// React Section component
import React, { useRef, useState } from "react";
import nextIcon from "../../assets/next-icon.svg";
import prevIcon from "../../assets/prev-icon.svg";
import costIcon from "../../assets/cost-icon.svg";
import accessIcon from "../../assets/access-icon.svg";
import decreasedRevenue from "../../assets/home-decreased-revenue.svg";
import disintermediation from "../../assets/disintermediation.svg";
import increasedRisk from "../../assets/home-increased-risk.svg";
import decreasedQuality from "../../assets/home-decresed-quality.svg";

export const Section4 = (): JSX.Element => {
  const ref = useRef(null);
  const [scrollX, setScrollX] = useState(0);
  // get viewport width
  const scroll = (scrollOffset: number): undefined => {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    // @ts-expect-error
    ref?.current?.scrollTo({
      top: 0,
      // @ts-expect-error
      left: (scrollX + scrollOffset) % (ref?.current?.clientWidth / 1.2),
      behavior: "smooth",
    });
    setScrollX(scrollX + scrollOffset);
    return undefined;
  };

  return (
    <div className="standard-container app mt-16">
      <div className="flex items-center">
        <h1 className="font-poppins text-3xl font-semibold text-outerspace">
          Facilities want solutions with controllable features/functionality, coupled with value-based pricing
        </h1>
        <div className="ml-auto hidden justify-end justify-self-end md:flex">
          <img
            src={prevIcon}
            alt=""
            width="100%"
            onClick={() => scroll(-(ref as any).current.clientWidth / 4)}
            className="cursor-pointer"
          />
          <img
            src={nextIcon}
            alt=""
            width="100%"
            onClick={() => scroll((ref as any).current.clientWidth / 4)}
            className="cursor-pointer"
          />
        </div>
      </div>
      <h2 className="mt-4 font-poppins text-aurometalsaurus">
        Without these options, facilities are at the mercy of market forces.
      </h2>

      <div
        className="no-scrollbars mt-16 flex snap-x snap-mandatory flex-col flex-nowrap gap-5 overflow-x-auto sm:flex-row"
        ref={ref}
      >
        <div className="hover:card-shadow w-full flex-none snap-always rounded-2xl border border-ultramarinblue p-4 sm:w-[45%] md:w-[23%]">
          <img src={costIcon} alt="" width="20%" className="mt-8" />
          <h1 className="mt-4 font-poppins text-xl font-semibold text-darkliver">Increased cost</h1>
          <p className="mb-8 mt-4 font-poppins text-aurometalsaurus">
            to acquire and retain talent, including agency and internal premium expense
          </p>
        </div>
        <div className="hover:card-shadow w-full flex-none snap-always rounded-2xl border border-ultramarinblue p-4 sm:w-[45%] md:w-[23%]">
          <img src={decreasedQuality} alt="" width="20%" className="mt-8" />
          <h1 className="mt-4 font-poppins text-xl font-semibold text-darkliver">Decreased Quality</h1>
          <p className="mb-8 mt-4 font-poppins text-aurometalsaurus">
            resulting from compromised nurse-to-patient ratio, culture, and values.
          </p>
        </div>
        <div className="hover:card-shadow w-full flex-none snap-always rounded-2xl border border-ultramarinblue p-4 sm:w-[45%] md:w-[23%]">
          <img src={increasedRisk} alt="" width="20%" className="mt-8" />
          <h1 className="mt-4 font-poppins text-xl font-semibold text-darkliver">Increased Risk</h1>
          <p className="mb-8 mt-4 font-poppins text-aurometalsaurus">
            to acquire and retain talent, including agency and internal premium expense
          </p>
        </div>
        <div className="hover:card-shadow w-full flex-none snap-always rounded-2xl border border-ultramarinblue p-4 sm:w-[45%] md:w-[23%]">
          <img src={decreasedRevenue} alt="" width="22%" className="mt-8" />
          <h1 className="mt-4 font-poppins text-xl font-semibold text-darkliver">Decreased Revenue</h1>
          <p className="mb-8 mt-4 font-poppins text-aurometalsaurus">
            when patient volume is rerouted to competitors, delayed, or lost.
          </p>
        </div>
        <div className="hover:card-shadow w-full flex-none snap-always rounded-2xl border border-ultramarinblue p-4 sm:w-[45%] md:w-[23%]">
          <img src={accessIcon} alt="" width="20%" className="mt-8" />
          <h1 className="mt-4 font-poppins text-xl font-semibold text-darkliver">Decreased Patient Access</h1>
          <p className="mb-8 mt-4 font-poppins text-aurometalsaurus">
            as facilities face staffing and scheduling shortfalls is rampant throughout{" "}
          </p>
        </div>
        <div className="hover:card-shadow w-full flex-none snap-always rounded-2xl border border-ultramarinblue p-4 sm:w-[45%] md:w-[23%]">
          <img src={disintermediation} alt="" width="14%" className="mt-8" />
          <h1 className="mt-4 font-poppins text-xl font-semibold text-darkliver">Increased disintermediation</h1>
          <p className="mb-8 mt-4 font-poppins text-aurometalsaurus">
            resulting from facilities inaction, compromise, or allowance of non-facility centric intermediaries
          </p>
        </div>
      </div>
    </div>
  );
};
