import React from "react";
import video1 from "../../assets/video-1.svg";
import video2 from "../../assets/video-2.svg";
import { Right } from "../Common/Right";

export const HeaderBottom = (): JSX.Element => {
  return (
    <div className="relative mt-16 overflow-visible">
      <div className="flex flex-[1_1_50%] items-center justify-center ">
        <Right />
      </div>

      <div className="mt-8 flex items-center justify-center">
        <div className="relative">
          <img src={video1} alt="" className="w-[200px] sm:w-[570px]" />
          {/* <div className="absolute bottom-1 -right-16 cursor-pointer sm:bottom-4 sm:right-4">
            <img src={playbutton} alt="" className="w-[50%] sm:w-[100%]" />
          </div> */}
        </div>
        <div className="relative">
          <img src={video2} alt="" className="w-[200px] sm:w-[570px]" />
          {/* <div className="absolute bottom-1 -right-16 cursor-pointer sm:bottom-4 sm:right-4">
            <img src={playbutton} alt="" className="w-[50%] sm:w-[100%]" />
          </div> */}
        </div>
      </div>
    </div>
  );
};
