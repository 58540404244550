import { NavBar } from "../../Common/NavBar";
import { Explore } from "../../Common/Explore";
import { Footer } from "../../Common/Footer";
import React from "react";

import heroImage from "../../../assets/product-integration/hero-image.svg";
import ehr1 from "../../../assets/product-integration/ehr-1.svg";
import ehr2 from "../../../assets/product-integration/ehr-2.svg";
import ehr3 from "../../../assets/product-integration/ehr-3.svg";
import hr1 from "../../../assets/product-integration/hr-1.svg";
import hr2 from "../../../assets/product-integration/hr-2.png";
import bg1 from "../../../assets/product-integration/bg-check-1.svg";
import bg2 from "../../../assets/product-integration/bg-check-2.svg";

const Section1 = (): JSX.Element => {
  return (
    <div className="app standard-container mt-16">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="order-2 col-span-1 mt-8 flex flex-col justify-center md:order-none md:mt-0">
          <h1 className="font-poppins text-[44px] font-bold text-ultramarinblue">Integrations</h1>
          <h2 className="mt-8 font-poppins text-[46px] leading-[60px]">
            Integrate Equitek, improve workforce management
          </h2>
          <p className="mt-8 pb-8 font-poppins text-[24px] font-light leading-[36px] text-darkliver md:pb-0">
            We understand staffing challenges facilities face. Partner with Equitek to seemlessly connect with
            healthcare professionals and workforce solutions ecosystem-unleash the full potential of your resources.
          </p>
        </div>
        <div className="relative col-span-1 flex items-center justify-center">
          <img src={heroImage} alt="hero image" className="" />
        </div>
      </div>
    </div>
  );
};

const Section2 = (): JSX.Element => {
  return (
    <div className="app standard-container mt-16">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="relative col-span-1 flex flex-row items-center pb-12">
          <div className="col-span-1 flex">
            {/* <img
              src={ehr1}
              alt="background right"
              className="-mr-36 scale-[60%] sm:-mr-40 sm:scale-[60%] md:-mr-40 md:scale-50 lg:-mr-32 lg:scale-[65%]"
            /> */}
            {/* <img src={ehr2} alt="background right" className="scale-[50%] sm:scale-[40%] md:scale-50 lg:scale-[55%]" /> */}
            <img src={ehr2} style={{ height: "16rem" }} />
            {/* <div className="absolute left-1/3 bottom-36 sm:bottom-48 sm:left-1/4 md:bottom-40 md:left-1/3">
              <img src={ehr3} alt="background stats" className="scale-100 md:scale-50 lg:scale-[100%]" />
            </div> */}
          </div>
        </div>
        <div className="col-span-1 flex items-center pb-4">
          <div className="flex md:p-8">
            <div className="px-4">
              <div className="w-24 border-4 border-[#E74040]"></div>
              <h1 className="mt-8 font-poppins text-[32px] font-semibold leading-[40px]">EHR Systems</h1>
              <p className="font-poppins text-[20px] font-light leading-[30px] text-darkliver">
                Integrate Equity&apos;s APIs and proprietary technologies for real-time alignment of staff and patients
                within your facility or health system.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-16 grid grid-cols-1 md:grid-cols-2">
        <div className="order-2 col-span-1 flex items-center pb-4 md:order-none">
          <div className="flex md:p-8">
            <div className="px-4">
              <div className="w-24 border-4 border-[#E74040]"></div>
              <h1 className="mt-8 font-poppins text-[32px] font-semibold leading-[40px]">
                HR, payroll, and timekeeping
              </h1>
              <p className="font-poppins text-[20px] font-light leading-[30px] text-darkliver">
                Connect with our integration partners for automated employee data transfer, faster and convenient
                payment, and real-time scheduling and staffing.
              </p>
            </div>
          </div>
        </div>
        <div className="relative col-span-1 pb-12">
          <div className="flex flex-row-reverse">
            <img
              src={hr2}
              alt="background image 1"
              className="-mr-16 -ml-56 scale-50 sm:mr-4 sm:-ml-52 sm:scale-[60%] md:-ml-64 md:scale-[40%] lg:-ml-28 lg:scale-50 "
            />
            <img
              src={hr1}
              alt="background image 1"
              className="-mr-20 scale-75 sm:-mr-8 sm:scale-75 md:-mr-8 md:scale-[50%] lg:-mr-28 lg:scale-75"
            />
          </div>
        </div>
      </div>
      <div className="mt-16 grid grid-cols-1 py-4 md:grid-cols-2">
        <div className="relative col-span-1 flex flex-row items-center pb-12">
          <div className="ml-18 relative">
            <img src={bg1} alt="background image 1" className="scale-75" />
            <div className="absolute -bottom-16 right-0">
              <img src={bg2} alt="background image 1" className="scale-60" />
            </div>
          </div>
        </div>
        <div className="col-span-1 pb-4">
          <div className="flex md:p-8">
            <div className="px-4">
              <div className="w-24 border-4 border-[#E74040]"></div>
              <h1 className="mt-8 font-poppins text-[32px] font-semibold leading-[40px]">Background check</h1>
              <p className="font-poppins text-[20px] font-light leading-[30px] text-darkliver">
                Reliable, accurate, and timely background checks designed to optimize hiring process.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const Integration = (): JSX.Element => {
  return (
    <div>
      <div style={{ background: "linear-gradient(89.88deg, rgba(255, 255, 255, 0) -3.92%, #B3E3FF 99.59%)" }}>
        <div>
          <NavBar className="app standard-container bg-transparent" />
        </div>
        <Section1 />
      </div>
      <Section2 />
      <Explore />
      <Footer />
    </div>
  );
};
