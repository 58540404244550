import React, { useEffect } from "react";

import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { AboutUs } from "./components/AboutUs";
import { MyShift } from "./components/Product/MyShift";
import { Home } from "./components/Home";
import { WorkforceManagement } from "./components/Product/Workforce Management";
import { Integration } from "./components/Product/Integration";
import { paths } from "./constants";
import { Analytics } from "./components/Product/Analytics";
import { ContactUs } from "./components/ContactUs";
import { PrivacyPolicy } from "./components/PrivacyPolicy/PrivacyPolicy";
import { TermsAndCondition } from "./components/TermsAndConditions/TermsAndCondition";

function ScrollToTop(): null {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App(): JSX.Element {
  return (
    <div className="w-full max-w-full overflow-hidden">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home />
              </>
            }
          />
          <Route
            path={paths.aboutUs}
            element={
              <>
                <AboutUs />
              </>
            }
          />
          <Route
            path={paths.myshift}
            element={
              <>
                <MyShift />
              </>
            }
          />
          <Route
            path={paths.workforceManagement}
            element={
              <>
                <WorkforceManagement />
              </>
            }
          />
          <Route
            path={paths.integration}
            element={
              <>
                <Integration />
              </>
            }
          />
          <Route
            path={paths.analytics}
            element={
              <>
                <Analytics />
              </>
            }
          />
          <Route
            path={paths.contactUs}
            element={
              <>
                <ContactUs />
              </>
            }
          />
          <Route
            path={paths.privacyPolicy}
            element={
              <>
                <PrivacyPolicy />
              </>
            }
          />
          <Route
            path={paths.termsAndConditions}
            element={
              <>
                <TermsAndCondition />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
