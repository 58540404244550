import React from "react";
import { NavBar } from "../Common/NavBar";
import heroImage from "../../assets/contact-us/hero-image.svg";
import heroImageCard from "../../assets/contact-us/hero-image-card.svg";
import bg1 from "../../assets/contact-us/bg1.svg";
import linkedin from "../../assets/contact-us/linkedin.svg";
import instagram from "../../assets/contact-us/instagram.svg";
import facebook from "../../assets/contact-us/facebook.svg";
import twitter from "../../assets/contact-us/twitter.svg";
import { Footer } from "../Common/Footer";
import axios from "axios";

const Section1 = (): JSX.Element => {
  return (
    <div className="overflow-hidden">
      <div className="standard-container app mt-16 grid grid-cols-1 md:grid-cols-2">
        <div className="order-2 col-span-1 mx-4 mt-8 flex flex-col justify-center md:order-none md:mx-8 md:mt-0">
          <p className="mt-8 pb-8 font-poppins text-[26px] font-light leading-[45px] text-darkliver md:pb-0">
            Equitek&apos;s mission is to develop a patient-centric staffing and scheduling software platform to help the
            healthcare industry tackle staffing shortage, reach supply-demand equilibrium; and improve healthcare
            professionals work-life balance while improving quality of care
          </p>
        </div>
        <div className="relative col-span-1 flex items-start justify-center md:items-end">
          <img src={bg1} alt="background image" className="absolute right-0 bottom-0 h-[300px] w-[900px] md:h-[50%]" />
          <img src={heroImage} alt="hero image" className="z-30 ml-28" />
          <div className="absolute left-4 bottom-2 flex h-[180px] w-[150px] flex-col items-center justify-center rounded-xl bg-white shadow-lg md:h-[200px]">
            <img src={heroImageCard} alt="card image" className="rounded-full" />
            <h1 className="mt-4 text-[14px] font-bold leading-[20px]">Dr. Shimanta</h1>
            <h2 className="mt-2 text-[12px] font-normal leading-[16px] text-darkliver">Skin Specialist</h2>
            <button className="mt-2 rounded-xl bg-[#4D77FF] p-2 font-semibold text-white">Make schedule</button>
          </div>
        </div>
      </div>
    </div>
  );
};

// {
//     "first_name":"John",
//     "last_name": "Doe",
//     "org_name": "xyz hospital",
//     "org_role": "Manager",
//     "org_website": "xyz.com",
//     "org_size": 200,
//     "comments": "Test comments",
//     "email": "john@xyz.com",
//     "reason": " test reason",
//     "phone": "123456789",
//     "channel": "ios"
// }
const Section2 = (): JSX.Element => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [orgName, setOrgName] = React.useState("");
  const [orgRole, setOrgRole] = React.useState("");
  const [orgWebsite, setOrgWebsite] = React.useState("");
  const [orgSize, setOrgSize] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [comments, setComments] = React.useState("");
  const [error, setError] = React.useState([{ field: "", message: "" }]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    console.log("submitting");
    const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const errors = [];
    if (firstName === "" || firstName.length < 1) {
      errors.push({ field: "firstName", message: "First name is required" });
    }
    if (lastName === "" || lastName.length < 1) {
      errors.push({ field: "lastName", message: "Last name is required" });
    }
    if (email === "" || email.length < 1 || !emailRegex.test(email)) {
      errors.push({ field: "email", message: "Email is required" });
    }
    // if (phone === "" || phone.length !== 10) {
    //   errors.push({ field: "phone", message: "Phone number is required" });
    // }
    // if (orgName === "" || orgName.length < 1) {
    //   errors.push({ field: "orgName", message: "Organization name is required" });
    // }
    // if (orgRole === "" || orgRole.length < 1) {
    //   errors.push({ field: "orgRole", message: "Organization role is required" });
    // }
    // if (orgWebsite === "" || orgWebsite.length < 1) {
    //   errors.push({ field: "orgWebsite", message: "Organization website is required" });
    // }
    // if (orgSize === "" || orgSize.length < 1) {
    //   errors.push({ field: "orgSize", message: "Organization size is required" });
    // }
    if (reason === "" || reason.length < 1) {
      errors.push({ field: "reason", message: "Reason is required" });
    }
    if (errors.length > 0) {
      setError(errors);
      return;
    }

    void axios
      .post("https://3umnd1ota3.execute-api.us-east-1.amazonaws.com/dev/contact", {
        first_name: firstName,
        last_name: lastName,
        org_name: orgName,
        org_role: orgRole,
        org_website: orgWebsite,
        org_size: orgSize,
        comments,
        email,
        reason,
        phone,
        channel: "web",
      })
      .then((res) => {
        // reload the page
        window.location.reload();
      })
      .catch(() => {
        setError([{ field: "general", message: "Something went wrong" }]);
      });
  };

  return (
    <div className="app standard-container mt-32 grid grid-cols-1 px-8 md:grid-cols-2">
      <div className="col-span-1">
        <h1 className="font-poppins text-[32px] font-bold leading-[40px]">
          Ready to regain control of workforce challenges and related costs?
        </h1>
        <p className="mt-16 font-poppins text-[20px] font-normal leading-[30px] text-darkliver">
          Submit your information and one of our team members will will reach out.
        </p>
        <hr className="mt-16 border-[#4175fa]" />
        <h1 className="mt-16 font-poppins text-[32px] font-bold leading-[40px]">With our solutions you can:</h1>
        <ul className="mt-16 ml-16">
          <li className="my-4 list-disc font-poppins text-[20px] font-normal leading-[30px] text-darkliver">
            Eliminate the middleman and stop relying on cost prohibitive third solutions
          </li>
          <li className="my-4 list-disc font-poppins text-[20px] font-normal leading-[30px] text-darkliver">
            Stop using archaic and inefficient staffing and workforce planning solutions
          </li>
          <li className="my-4 list-disc font-poppins text-[20px] font-normal leading-[30px] text-darkliver">
            Enable direct, fast reach to qualified candidates who fit your organization&apos;s culture
          </li>
        </ul>
      </div>
      <div className="col-span-1">
        <form className="mx-4 rounded-xl border p-8 shadow-lg md:mx-8" onSubmit={handleSubmit}>
          <div className="flex grow flex-col pb-8">
            <div className="mt-4 flex flex-col gap-8 xl:flex-row">
              <div className="flex grow flex-col">
                <label htmlFor="firstName" className="font-poppins text-[20px] font-normal leading-[30px]">
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="Enter your first name"
                  className="mt-4 rounded-xl bg-[#F5F5F5] p-4"
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {error.map((err, index) => {
                  if (err.field === "firstName") {
                    return (
                      <p key={index} className="mt-1 text-xs italic text-red-500">
                        {err.message}
                      </p>
                    );
                  }
                  return <></>;
                })}
              </div>
              <div className="flex grow flex-col">
                <label htmlFor="lastName" className="font-poppins text-[20px] font-normal leading-[30px]">
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Enter your last name"
                  className="mt-4 rounded-xl bg-[#F5F5F5] p-4"
                  onChange={(e) => setLastName(e.target.value)}
                />
                {error.map((err, index) => {
                  if (err.field === "lastName") {
                    return (
                      <p key={index} className="mt-1 text-xs italic text-red-500">
                        {err.message}
                      </p>
                    );
                  }
                  return <></>;
                })}
              </div>
            </div>
            <div className="mt-8 flex grow flex-col">
              <label htmlFor="email" className="font-poppins text-[20px] font-normal leading-[30px]">
                Email
              </label>
              <input
                type="text"
                name="email"
                id="email"
                placeholder="email@example.com"
                className="mt-4 rounded-xl bg-[#F5F5F5] p-4"
                onChange={(e) => setEmail(e.target.value)}
              />
              {error.map((err, index) => {
                if (err.field === "email") {
                  return (
                    <p key={index} className="mt-1 text-xs italic text-red-500">
                      {err.message}
                    </p>
                  );
                }
                return <></>;
              })}
            </div>
            <div className="mt-8 flex grow flex-col">
              <label htmlFor="phone" className="font-poppins text-[20px] font-normal leading-[30px]">
                Phone Number
              </label>
              <input
                type="text"
                name="phone"
                id="phone"
                placeholder="(555) 555-555"
                className="mt-4 rounded-xl bg-[#F5F5F5] p-4"
                onChange={(e) => setPhone(e.target.value)}
              />
              {error.map((err, index) => {
                if (err.field === "phone") {
                  return (
                    <p key={index} className="mt-1 text-xs italic text-red-500">
                      {err.message}
                    </p>
                  );
                }
                return <></>;
              })}
            </div>
            <div className="mt-8 flex grow flex-col">
              <label htmlFor="orgName" className="font-poppins text-[20px] font-normal leading-[30px]">
                Organization Name
              </label>
              <input
                type="text"
                name="orgName"
                id="orgName"
                placeholder="Enter your organization name"
                className="mt-4 rounded-xl bg-[#F5F5F5] p-4"
                onChange={(e) => setOrgName(e.target.value)}
              />
              {error.map((err, index) => {
                if (err.field === "orgName") {
                  return (
                    <p key={index} className="mt-1 text-xs italic text-red-500">
                      {err.message}
                    </p>
                  );
                }
                return <></>;
              })}
            </div>
            <div className="mt-8 flex grow flex-col">
              <label htmlFor="orgRole" className="font-poppins text-[20px] font-normal leading-[30px]">
                Organization Role
              </label>
              <input
                type="text"
                name="orgRole"
                id="orgRole"
                placeholder="Enter your organization role"
                className="mt-4 rounded-xl bg-[#F5F5F5] p-4"
                onChange={(e) => setOrgRole(e.target.value)}
              />
              {error.map((err, index) => {
                if (err.field === "orgRole") {
                  return (
                    <p key={index} className="mt-1 text-xs italic text-red-500">
                      {err.message}
                    </p>
                  );
                }
                return <></>;
              })}
            </div>
            <div className="mt-8 flex grow flex-col">
              <label htmlFor="orgWebsite" className="font-poppins text-[20px] font-normal leading-[30px]">
                Organization Website
              </label>
              <input
                type="text"
                name="orgWebsite"
                id="orgWebsite"
                placeholder="Enter your organization website"
                className="mt-4 rounded-xl bg-[#F5F5F5] p-4"
                onChange={(e) => setOrgWebsite(e.target.value)}
              />
              {error.map((err, index) => {
                if (err.field === "orgWebsite") {
                  return (
                    <p key={index} className="mt-1 text-xs italic text-red-500">
                      {err.message}
                    </p>
                  );
                }
                return <></>;
              })}
            </div>
            <div className="mt-8 flex grow flex-col">
              <label htmlFor="orgSize" className="font-poppins text-[20px] font-normal leading-[30px]">
                Organization Size
              </label>
              <input
                type="number"
                name="orgSize"
                id="orgSize"
                placeholder="Enter your organization size"
                className="mt-4 rounded-xl bg-[#F5F5F5] p-4"
                onChange={(e) => setOrgSize(e.target.value)}
              />
              {error.map((err, index) => {
                if (err.field === "orgSize") {
                  return (
                    <p key={index} className="mt-1 text-xs italic text-red-500">
                      {err.message}
                    </p>
                  );
                }
                return <></>;
              })}
            </div>
            <div className="mt-8 flex grow flex-col">
              <label htmlFor="reason" className="font-poppins text-[20px] font-normal leading-[30px]">
                How can our sales team help you?
              </label>
              <input
                type="text"
                name="reason"
                id="reason"
                placeholder="Enter your reason"
                className="mt-4 rounded-xl bg-[#F5F5F5] p-4"
                onChange={(e) => setReason(e.target.value)}
              />
              {error.map((err, index) => {
                if (err.field === "reason") {
                  return (
                    <p key={index} className="mt-1 text-xs italic text-red-500">
                      {err.message}
                    </p>
                  );
                }
                return <></>;
              })}
            </div>
            <div className="mt-8 flex grow flex-col">
              <label htmlFor="message" className="font-poppins text-[20px] font-normal leading-[30px]">
                Anything else? (Optional)
              </label>
              <textarea
                name="comment"
                id="comment"
                placeholder="Enter your comments here"
                className="mt-4 rounded-xl bg-[#F5F5F5] p-4"
                onChange={(e) => setComments(e.target.value)}
              />
              {error.map((err, index) => {
                if (err.field === "comment") {
                  return (
                    <p key={index} className="mt-1 text-xs italic text-red-500">
                      {err.message}
                    </p>
                  );
                }
                return <></>;
              })}
            </div>
            {/*    Submit button */}
            <div className="mt-8 flex justify-center">
              <button
                type="submit"
                className="grow rounded-xl bg-[#4175fa] p-4 font-poppins text-[20px] font-normal leading-[30px] text-white"
              >
                Submit
              </button>
              {error.map((err, index) => {
                if (err.field === "general") {
                  return (
                    <p key={index} className="mt-1 text-xs italic text-red-500">
                      {err.message}
                    </p>
                  );
                }
                return <></>;
              })}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export const SocialMediaExplore = (): JSX.Element => {
  return (
    <div className="standard-container app my-12 pt-16">
      <div className="rounded bg-aliceblue px-4 pt-8 pb-8 text-center shadow-lg shadow-[#bcbbe854] md:px-36 md:pt-20 md:pb-20">
        <h1 className="font-poppins text-[28px] font-bold leading-[48px] text-[#2B2962] md:text-[40px]">
          Have a general question?
        </h1>
        <h2 className="mt-4 font-poppins text-[28px] font-bold leading-[48px] text-[#4175FA] md:text-[40px]">
          support@equitek.net
        </h2>
        <p className="mx-4 mt-8 text-darkliver">Follow us</p>
        <div className="mt-4 flex cursor-pointer justify-center">
          <img src={twitter} alt="twitter" className="mx-4" />
          <img src={facebook} alt="facebook" className="mx-4" />
          <img src={instagram} alt="instagram" className="mx-4" />
          <img src={linkedin} alt="linkedin" className="mx-4" />
        </div>
      </div>
    </div>
  );
};

export const ContactUs = (): JSX.Element => {
  return (
    <div>
      <NavBar className="standard-container app bg-transparent px-4" />
      <div className="app my-12 pt-16">
        <h1 className="font-poppins text-[44px] font-bold text-ultramarinblue">Contact Us</h1>
      </div>
      {/* <Section1 /> */}
      <Section2 />
      <SocialMediaExplore />
      <Footer />
    </div>
  );
};
