import React from "react";
import { HeaderCenter } from "./HeaderCenter";
import { NavBar } from "../Common/NavBar";
import { HeaderBottom } from "./HeaderBottom";

const Header = (): JSX.Element => {
  return (
    <header className="bg-primary">
      <div className="standard-container app">
        <NavBar />
        <HeaderCenter />
        <HeaderBottom />
      </div>
    </header>
  );
};

export default Header;
