import rightClinic from "../../assets/common/right-clinician.svg";
import rightSetting from "../../assets/common/right-setting.svg";
import rightTime from "../../assets/common/right-time.svg";
import React from "react";

export const Right = (): JSX.Element => {
  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
      <div className="col-span-1 flex items-center rounded-2xl border bg-white">
        <img src={rightClinic} className="h-20 w-20 p-1" alt="" />
        <h1
          className="text-center font-poppins text-2xl font-bold leading-[50px] text-ultramarinblue"
          style={{ lineHeight: 1.5, fontSize: "1.3rem" }}
        >
          Right Clinician
        </h1>
      </div>
      <div className="col-span-1 flex items-center rounded-2xl border bg-white px-4">
        <img src={rightSetting} className="h-20 w-20 p-1" alt="" />
        <h1
          className="text-center font-poppins text-2xl font-bold leading-[50px] text-ultramarinblue"
          style={{ lineHeight: 1.5, fontSize: "1.3rem" }}
        >
          Right Setting
        </h1>
      </div>
      <div className="col-span-1 flex items-center rounded-2xl border bg-white px-4">
        <img src={rightTime} className="h-20 w-20 p-1" alt="" />
        <h1
          className="text-center font-poppins text-2xl font-bold leading-[50px] text-ultramarinblue"
          style={{ lineHeight: 1.5, fontSize: "1.3rem" }}
        >
          Right Time
        </h1>
      </div>
    </div>
  );
};
