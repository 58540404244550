import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

function ResponsiveDebugger(): JSX.Element {
  // const styles = makeStyles((_theme) => ({
  //   root: {
  //     position: "absolute",
  //     bottom: 0,
  //     right: 0,
  //     marginBottom: "1rem",
  //     "&:after": {
  //       display: "block",
  //       marginBottom: "1rem",
  //       position: "absolute",
  //       [_theme.breakpoints.down("sm")]: {
  //         content: "'Size: 0-600px - sm'",
  //         backgroundColor: _theme.palette.primary.light,
  //       },
  //       [_theme.breakpoints.between("sm", "md")]: {
  //         content: "'Size: 600-900px - md'",
  //         backgroundColor: _theme.palette.secondary.light,
  //       },
  //       [_theme.breakpoints.between("md", "lg")]: {
  //         content: "'Size: 900-1440px - lg'",
  //         backgroundColor: _theme.palette.error.light,
  //       },
  //       [_theme.breakpoints.between("lg", "xl")]: {
  //         content: "'Size: 1440-1920px - xl'",
  //         backgroundColor: _theme.palette.warning.light,
  //       },
  //       [_theme.breakpoints.up("xl")]: {
  //         content: "'Size: 1920+px - xl'",
  //         backgroundColor: _theme.palette.info.light,
  //       },
  //     },
  //   },
  // }));

  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  useEffect(() => {
    const listener = (): void => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  });

  return (
    <div className={`static bottom-0 right-0 mb-4 after:absolute after:mb-4 after:block after:sm:content-["ms"]`}>
      Width:{width}, Height: {height}
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
