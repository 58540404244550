import React from "react";
import heroShadow from "../../assets/hero-shadow.svg";
import hero from "../../assets/home/dashboard.svg";
import star from "../../assets/home/star.svg";
import image1 from "../../assets/home/1.svg";
import image2 from "../../assets/home/2.svg";
import image3 from "../../assets/home/3.svg";
import image4 from "../../assets/home/4.svg";
import { useNavigate } from "react-router-dom";
import { paths } from "../../constants";

export const HeaderCenter = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div className="relative overflow-visible leading-[60px] md:mt-16">
      <div className="mt-8 flex flex-col-reverse md:mt-0 md:h-[70%] md:flex-row">
        <div className="mt-32 flex flex-[1_1_60%] flex-col justify-center px-4 text-center md:mt-0 md:pl-0 md:text-left">
          <h1 className="font-poppins text-5xl font-bold leading-[60px] text-darkliver">
            Staffing Platform as a Service (SPaaS) and Workforce Management
          </h1>
          <h3 className="mt-8 font-poppins text-xl font-light leading-[40px] text-darkliver">
            Built for healthcare facilities, by healthcare operators, without relying on cost prohibitive staffing
            agencies, archaic and inefficient staffing and scheduling solutions.
          </h3>
          <div className="mt-8">
            <a
              href="#why-equitek"
              className="mx-4 w-full cursor-pointer rounded-full border border-solid border-ultramarinblue py-2 px-4 font-poppins text-xl font-medium leading-[30px] text-ultramarinblue md:mx-0 md:w-auto"
            >
              Learn More
            </a>
            <button
              onClick={() => {
                navigate(paths.contactUs);
              }}
              className="mx-4 w-full cursor-pointer rounded-full bg-ultramarinblue py-2 px-6 font-poppins text-xl font-medium text-white md:ml-8 md:w-auto"
            >
              Request Demo
            </button>
          </div>
        </div>
        <div className="flex flex-[1_1_40%] justify-center ">
          <div className="relative flex w-full justify-center">
            <div className="absolute -left-[10%] blur-2xl md:-top-[2%]">
              <img src={heroShadow} alt="hero shadow" className="mx-auto h-[80%] scale-50 rounded-2xl" />
            </div>
            {/* <div className="glass absolute top-[60%] right-[5%] z-40 min-w-[40%]">
              <div className="flex flex-col items-start rounded-2xl rounded-br-[62px] bg-[#8BF1FFA3] px-4 py-2">
                <p>Who has joined with us</p>
                <div className="flex">
                  <img src={image1} alt="Avatar" className="-ml-[0%] h-12 w-12 shrink rounded-full border-white" />
                  <img src={image2} alt="Avatar" className="-ml-[8%] h-12 w-12 shrink rounded-full border-white" />
                  <img src={image3} alt="Avatar" className="-ml-[8%] h-12 w-12 shrink rounded-full border-white" />
                  <img src={image4} alt="Avatar" className="-ml-[8%] h-12 w-12 shrink rounded-full border-white" />
                  <div className="-ml-[8%] flex flex h-12 w-12 shrink items-center justify-center rounded-full border-4 border border-white bg-[#C8F2FF] text-xs">
                    100+
                  </div>
                </div>
                <div className="mt-2 flex items-center justify-center gap-2">
                  <img src={star} alt="Avatar" className="-ml-[0%] h-6 w-6" />
                  <img src={star} alt="Avatar" className="-ml-[0%] h-6 w-6" />
                  <img src={star} alt="Avatar" className="-ml-[0%] h-6 w-6" />
                  <img src={star} alt="Avatar" className="-ml-[0%] h-6 w-6" />
                  <img src={star} alt="Avatar" className="-ml-[0%] h-6 w-6" />
                </div>
              </div>
            </div> */}
            <div className="z-10 text-center">
              <img src={hero} alt="" className="mx-auto w-[100vw] scale-75 rounded-2xl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
