import React from "react";
import { NavBar } from "../Common/NavBar";
import improve from "../../assets/about-us-improve.svg";
import deliver from "../../assets/about-us-deliver.svg";
import middleman from "../../assets/about-us-middleman.svg";
import reduceCost from "../../assets/about-us-reduce-cost.svg";
import wonCee from "../../assets/Tim-Steele.jpg";
import womanCheckup from "../../assets/young-woman-receiving-dental-check-up 1.png";
import femaleDentist from "../../assets/male-female-dentist-examining-patient 1.png";
import womanPatient from "../../assets/woman-patient-dentist 1.png";
import ralphEdwards from "../../assets/Gaston-Bushiri.jpg";
import robertFox from "../../assets/Raman-Abbu.jpg";
import gideon from "../../assets/Gideon-Okonrende.jpg";
import people from "../../assets/about-us-people.svg";
import eleanorPena from "../../assets/Michael-Lawson.jpg";
import { Footer } from "../Common/Footer";
import { Explore } from "../Common/Explore";
import { Right } from "../Common/Right";
import { Link } from "react-router-dom";
import { paths } from "../../constants";

export const AboutUs = (): JSX.Element => {
  return (
    <div>
      <NavBar className="app standard-container w-full bg-white shadow-lg" />

      <div className="app standard-container">
        {/* -------------------------------------------- Card ------------------------------------------------ */}

        <div className="relative">
          <div className="text-center sm:hidden">
            <Right />
          </div>

          <div className="my-12 hidden p-24 sm:block">
            <Right />
          </div>

          <div style={{ background: "linear-gradient(0deg, #90D5FC -82.34%, #008DDE 86.19%)" }} className="rounded-3xl">
            <div className="mt-8 p-8 sm:mt-0 sm:pb-32">
              <h1 className="font-poppins text-[34px] font-bold leading-[50px] text-white">
                Helping facilities tackle staffing and scheduling challenges head on
              </h1>
              <h2 className="font-poppins text-[28px] text-white">
                From ambulatory surgery centres to multi-state health systems, we help facilities throughout North
                America staff and manage workforce.
              </h2>
              <div className="mt-8 flex flex-col md:flex-row">
                <Link
                  to={paths.contactUs}
                  className="mx-4 w-full cursor-pointer rounded-full bg-ultramarinblue py-2 px-6 font-poppins text-xl font-medium text-white md:ml-8 md:w-auto"
                >
                  Request Demo
                </Link>
              </div>
            </div>
            {/* center crop image */}
            <div className="visible flex items-center justify-center sm:absolute sm:mx-auto sm:hidden">
              <img
                src={people}
                alt="people"
                className="!h-[200px] !w-[700px] object-cover sm:h-auto sm:!h-auto sm:!w-auto"
              />
            </div>
          </div>
          <div className="mx-4 -mt-16 hidden justify-center sm:flex lg:mx-16">
            <img src={people} alt="" />
          </div>
        </div>

        {/* -------------------------------------------- Story ------------------------------------------------ */}
        <div className="mt-16 md:hidden">
          <h1 className="text-center font-poppins text-[40px] font-bold text-yankeesblue">Our Story</h1>
          <div>
            <img src={womanCheckup} className="mx-auto w-[100vw] scale-75 rounded-2xl text-center" alt="" />
          </div>
          <p className="py-8 text-justify font-poppins text-2xl text-darkliver opacity-70 md:px-24">
            After years of experiencing recurring cost prohibitive solutions to healthcare staffing and scheduling
            challenges firsthand, which were exacerbated during the COVID-19 pandemic, Gaston started Equitek in 2020
            and enlisted the help of his colleagues Michael and Gideon shortly thereafter.
            <br />
            Their goal was to develop an innovative end-to-end technology platform that powers MyShiftHealth mobile and
            web applications to help healthcare facilities regain control of staffing challenges and related costs,
            improve healthcare professionals work-life balance, and deliver high quality patient care.
          </p>
        </div>

        <div className="mt-16 hidden grid-cols-2 md:grid">
          <div className="col-span-1">
            <h1 className="font-poppins text-[40px] font-bold text-yankeesblue">Our Story</h1>
            <p className="py-8 pr-12 font-poppins text-2xl text-darkliver opacity-60">
              After years of experiencing recurring cost prohibitive solutions to healthcare staffing and scheduling
              challenges firsthand, which were exacerbated during the COVID-19 pandemic, Gaston started Equitek in 2020
              and enlisted the help of his colleagues Michael and Gideon shortly thereafter.
              <br /> Their goal was to develop an innovative end-to-end technology platform that powers MyShiftHealth
              mobile and web applications to help healthcare facilities regain control of staffing challenges and
              related costs, improve healthcare professionals work-life balance, and deliver high quality patient care.
            </p>
          </div>
          <div className="col-span-1 mt-60 flex">
            <img src={womanCheckup} className="mx-4 mb-24 h-[40%] w-auto rounded-3xl text-center lg:h-3/4" alt="" />
            <img src={womanPatient} className="mx-4 mt-24 h-[40%] w-auto rounded-3xl text-center lg:h-3/4" alt="" />
          </div>
        </div>

        {/* -------------------------------------------- Mission ------------------------------------------------ */}

        <div className="mt-16 md:hidden">
          <h1 className="text-center font-poppins text-[40px] font-bold text-yankeesblue">Our Mission</h1>
          <div className="mt-12">
            <img src={femaleDentist} className="mx-auto w-[100vw] scale-90 rounded-2xl text-center" alt="" />
          </div>
          <p className="py-8 text-justify font-poppins text-2xl text-darkliver opacity-60 md:px-24">
            Develop a patient-centric staffing and scheduling software platform to help the healthcare industry tackle
            staffing shortage, reach supply-demand equilibrium; and improve healthcare professionals work-life balance
            while improving quality of care.
          </p>
        </div>

        <div className="mt-16 hidden grid-flow-row-dense grid-cols-2 md:grid">
          <div className="order-last col-span-1 ml-16">
            <h1 className="font-poppins text-[40px] font-bold text-yankeesblue">Our Mission</h1>
            <p className="py-8 pr-12 font-poppins text-2xl text-darkliver opacity-60">
              Develop a patient-centric staffing and scheduling software platform to help the healthcare industry tackle
              staffing shortage, reach supply-demand equilibrium; and improve healthcare professionals work-life balance
              while improving quality of care.
            </p>
          </div>
          <div className="col-span-1 mt-32 flex">
            <img src={womanCheckup} className="mx-4 mb-24 h-[40%] w-auto rounded-3xl text-center lg:h-3/4" alt="" />
            <img src={womanPatient} className="mx-4 mt-24 h-[40%] w-auto rounded-3xl text-center lg:h-3/4" alt="" />
          </div>
        </div>

        {/* -------------------------------------------- Commitments ------------------------------------------------ */}

        <div className="mt-16 w-full py-4 md:px-2 md:py-4">
          <h1 className="text-center font-poppins text-[40px] font-bold text-yankeesblue">Our Commitments</h1>
          <div className="mt-12 grid grid-cols-1 gap-4 md:grid-cols-2 md:px-8">
            <div className="col-span-1 rounded-2xl border border-solid border-[#EAF0FF] md:grid md:grid-cols-5">
              <div className="flex flex-col content-center items-center justify-center rounded-t-2xl bg-[#DBEFFA] py-8 md:col-span-2 md:rounded-none md:rounded-l-2xl">
                <img src={middleman} alt="" />
              </div>
              <div className="rounded-b-2xl py-8 text-center md:col-span-3 md:px-4 md:text-left">
                <h1 className="text-yankessblue px-12 py-4 font-poppins text-[20px] leading-[28px] md:px-0">
                  <b>Eliminates</b> the middleman
                </h1>
                <p className="text-yankessblue py-4 font-poppins text-[14px] leading-[28px] opacity-50 md:text-[16px] lg:text-[18px] xl:text-[20px]">
                  Connect with healthcare professionals directly - on your terms
                </p>
              </div>
            </div>
            <div className="col-span-1 rounded-2xl border border-solid border-[#EAF0FF] md:grid md:grid-cols-5">
              <div className="flex flex-col content-center items-center justify-center rounded-t-2xl bg-[#FADBE2] py-8 md:col-span-2 md:rounded-none md:rounded-l-2xl ">
                <img src={reduceCost} alt="" />
              </div>
              <div className="rounded-b-2xl py-8 text-center md:col-span-3 md:px-4 md:text-left">
                <h1 className="text-yankessblue px-12 py-4 font-poppins text-[20px] leading-[28px] md:px-0">
                  <b>Reduce</b> labor costs & risks associated with staffing shortages
                </h1>
                <p className="text-yankessblue py-4 font-poppins text-[14px] leading-[28px] opacity-50 md:text-[16px] lg:text-[18px] xl:text-[20px]">
                  Deliver cost-effective patient care, while optimizing workforce management and improving quality.
                </p>
              </div>
            </div>

            <div className="col-span-1 rounded-2xl border border-solid border-[#EAF0FF] md:grid md:grid-cols-5">
              <div className="flex flex-col content-center items-center justify-center rounded-t-2xl bg-[#F6DBFA] py-8 md:col-span-2 md:rounded-none md:rounded-l-2xl">
                <img src={improve} alt="" />
              </div>
              <div className="rounded-b-2xl py-8 text-center md:col-span-3 md:px-4 md:text-left">
                <h1 className="text-yankessblue px-12 py-4 font-poppins text-[20px] leading-[28px] md:px-0">
                  <b>Improve</b> workforce management with surgical precision
                </h1>
                <p className="text-yankessblue py-4 font-poppins text-[14px] leading-[28px] opacity-50 md:text-[16px] lg:text-[18px] xl:text-[20px]">
                  Remove barriers that hinder optimal management of healthcare workforce; achieve effective and
                  efficient staffing and scheduling.
                </p>
              </div>
            </div>
            <div className="col-span-1 rounded-2xl border border-solid border-[#EAF0FF] md:grid md:grid-cols-5">
              <div className="flex flex-col content-center items-center justify-center rounded-t-2xl bg-[#FADBE2] py-8 md:col-span-2 md:rounded-none md:rounded-l-2xl">
                <img src={deliver} alt="" />
              </div>
              <div className="rounded-b-2xl py-8 text-center md:col-span-3 md:px-4 md:text-left">
                <h1 className="text-yankessblue px-12 py-4 font-poppins text-[20px] leading-[28px] md:px-0">
                  <b>Deliver</b> world-class, tech-enabled, & market-leading solutions
                </h1>
                <p className="text-yankessblue py-4 font-poppins text-[14px] leading-[28px] opacity-50 md:text-[16px] lg:text-[18px] xl:text-[20px]">
                  Deliver game-changing, innovate solutions that are simple and efficient, while improving
                  organizational competitive advantage
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* -------------------------------------------- Team ------------------------------------------------ */}

        <div className="mt-16">
          <div className="md:flex">
            <h1 className="text-center font-poppins text-6xl font-bold text-yankeesblue">Meet Our Team</h1>
            <div className="divide-none divide-bluesapphire md:ml-auto md:flex md:divide-x">
              <div className="my-12 md:my-0 md:mx-4">
                <h2 className="text-center font-poppins text-[40px] font-bold text-[#005BFF]">50 years</h2>
                <h2 className="text-center font-poppins text-darkliver">Healthcare management expertise</h2>
              </div>
              <div className="my-12 border-l-[#005BFF] md:my-0 md:mx-4 md:border-2 md:border">
                <h2 className="text-center font-poppins text-[40px] font-bold text-[#005BFF]">Deep knowledge</h2>
                <h2 className="text-center font-poppins text-darkliver">in healthcare operational challenges</h2>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 py-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-0">
            {/* Card which contains image and person info at the bottom and on hover show more information about the person */}
            <div className="group relative col-span-1 m-4 flex flex-col rounded-2xl border border-solid border-[#EAF0FF]">
              <div className="flex content-center items-center justify-center">
                <img
                  src={ralphEdwards}
                  width="100%"
                  className="object-cover object-top"
                  style={{ height: "450px" }}
                  alt=""
                />
              </div>
              <div className="rounded-b-2xl text-center md:px-4 md:text-left">
                <h1 className="text-yankessblue px-12 py-1 font-poppins text-[20px] leading-[28px] md:px-0">
                  <b>Gaston Bushiri </b>
                </h1>
                <p className="text-yankessblue py-1 font-poppins text-[14px] leading-[28px] opacity-50 md:text-[16px] lg:text-[18px] xl:text-[20px]">
                  Co-Founder, Chief Executive Officer
                </p>
              </div>
              <div
                className="invisible absolute top-0 left-0 w-full bg-white p-2 group-hover:visible group-hover:transition-all"
                style={{ height: "calc(100% - 60px)" }}
              >
                <p className="h-full overflow-y-scroll text-lg">
                  Gaston is a result-oriented and innovative finance executive with over 15 years of experience in
                  healthcare financial management and operations, working for some of the best healthcare systems in the
                  nation such as TSPMG (Kaiser Permanente), Prime Healthcare, and OhioHealth. Originally from the
                  Democratic Republic of Congo, Gaston graduated from The University of North Carolina at Greensboro
                  with a Bachelor degree in accounting, and Georgia State University with Master degrees in Business and
                  Healthcare Administration. When not helping healthcare systems improve lives, he enjoys family,
                  exercising, world news and volunteering.
                </p>
              </div>
              {/*  Yes by default and no on group-hover */}
              <h1 className="visible bottom-0 left-4 mt-auto px-4 py-4 font-poppins font-bold text-blue-500 group-hover:visible group-hover:invisible">
                More about Gaston
              </h1>
              <h1 className="invisible absolute bottom-0 left-4 py-4 font-poppins font-bold text-blue-500 group-hover:visible">
                Less about Gaston
              </h1>
            </div>
            <div className="group relative col-span-1 m-4 flex flex-col rounded-2xl border border-solid border-[#EAF0FF]">
              <div className="flex content-center items-center justify-center">
                <img src={gideon} width="100%" className="object-cover object-top" style={{ height: "450px" }} alt="" />
              </div>
              <div className="rounded-b-2xl text-center md:px-4 md:text-left">
                <h1 className="text-yankessblue px-12 py-1 font-poppins text-[20px] leading-[28px] md:px-0">
                  <b>Gideon Okonrende</b>
                </h1>
                <p className="text-yankessblue py-1 font-poppins text-[14px] leading-[28px] opacity-50 md:text-[16px] lg:text-[18px] xl:text-[20px]">
                  Co-Founder, Chief Information Officer
                </p>
              </div>
              <div
                className="invisible absolute top-0 left-0 w-full bg-white p-2 group-hover:visible group-hover:transition-all"
                style={{ height: "calc(100% - 60px)" }}
              >
                <p className="h-full overflow-y-scroll text-lg">
                  Highly relational, result oriented, and change agent leader with over 21years of IT and Business
                  experience leading highly effective geographically dispersed teams and with an entrepreneurial spirit.
                  Successful track record delivering complex transformational programs in the healthcare, retail,
                  non-profit, financial, and manufacturing industries. Offering exceptional interpersonal skills and
                  problem solving, dedicated to connecting resources and collaborating with teams to create processes
                  that lead to equity, access and opportunities that allows staff to achieve their full potential and an
                  organization to amplify its mission.
                </p>
              </div>
              {/*  Yes by default and no on group-hover */}
              <h1 className="visible bottom-0 left-4 mt-auto px-4 py-4 font-poppins font-bold text-blue-500 group-hover:visible group-hover:invisible">
                More about Gideon
              </h1>
              <h1 className="invisible absolute bottom-0 left-4 py-4 font-poppins font-bold text-blue-500 group-hover:visible">
                Less about Gideon
              </h1>
            </div>
            <div className="group relative col-span-1 m-4 flex flex-col rounded-2xl border border-solid border-[#EAF0FF]">
              <div className="flex content-center items-center justify-center">
                <img
                  src={eleanorPena}
                  width="100%"
                  className="object-cover object-top"
                  style={{ height: "450px" }}
                  alt=""
                />
              </div>
              <div className="rounded-b-2xl text-center md:px-4 md:text-left">
                <h1 className="text-yankessblue px-12 py-1 font-poppins text-[20px] leading-[28px] md:px-0">
                  <b>Michael Lawson</b>
                </h1>
                <p className="text-yankessblue py-1 font-poppins text-[14px] leading-[28px] opacity-50 md:text-[16px] lg:text-[18px] xl:text-[20px]">
                  Co-Founder, President
                </p>
              </div>
              <div
                className="invisible absolute top-0 left-0 w-full bg-white p-2 group-hover:visible group-hover:transition-all"
                style={{ height: "calc(100% - 60px)" }}
              >
                <p className="h-full overflow-y-scroll text-lg">
                  Michael is an accomplished healthcare executive with over 30 years of experience at exceptional
                  internationally known organizations such as the Cleveland Clinic and OhioHealth. He is a results
                  oriented leader overseeing all facets of healthcare business for multiple caresites with a track
                  record of driving results in strategic growth, profitability, operations and leading transformation
                  initiatives. Michael holds a BA in business at Baldwin Wallace University and an MBA at Cleveland
                  State University. He is a board of trustee, member and advisor at several healthcare, education and
                  business organizations.
                </p>
              </div>
              {/*  Yes by default and no on group-hover */}
              <h1 className="visible bottom-0 left-4 mt-auto px-4 py-4 font-poppins font-bold text-blue-500 group-hover:visible group-hover:invisible">
                More about Michael
              </h1>
              <h1 className="invisible absolute bottom-0 left-4 py-4 font-poppins font-bold text-blue-500 group-hover:visible">
                Less about Michael
              </h1>
            </div>
            <div className="group relative col-span-1 m-4 flex flex-col rounded-2xl border border-solid border-[#EAF0FF]">
              <div className="flex content-center items-center justify-center">
                <img
                  src={robertFox}
                  width="100%"
                  className="object-cover object-top"
                  style={{ height: "450px" }}
                  alt=""
                />
              </div>
              <div className="rounded-b-2xl text-center md:px-4 md:text-left">
                <h1 className="text-yankessblue px-12 py-1 font-poppins text-[20px] leading-[28px] md:px-0">
                  <b>Raman Abbu</b>
                </h1>
                <p className="text-yankessblue py-1 font-poppins text-[14px] leading-[28px] opacity-50 md:text-[16px] lg:text-[18px] xl:text-[20px]">
                  Chief Technology Officer
                </p>
              </div>
              <div
                className="invisible absolute top-0 left-0 w-full bg-white p-2 group-hover:visible group-hover:transition-all"
                style={{ height: "calc(100% - 60px)" }}
              >
                <p className="h-full overflow-y-scroll text-lg">
                  Raman has over 25 years in all facets of Information Technology with a track record of quality and
                  on-time project delivery in a variety of industries including healthcare, manufacturing, and telecom.
                  He is a strategic leader and a results-driven implementation professional, with a demonstrated ability
                  to architect solutions and successfully lead business or technical initiatives. Raman earned his
                  bachelor’s degree in Computer Science from Gulbarga University. When he is not leading IT projects,
                  Raman enjoys volunteering in his community in multiple capacities
                </p>
              </div>
              {/*  Yes by default and no on group-hover */}
              <h1 className="visible bottom-0 left-4 mt-auto px-4 py-4 font-poppins font-bold text-blue-500 group-hover:visible group-hover:invisible">
                More about Raman
              </h1>
              <h1 className="invisible absolute bottom-0 left-4 py-4 font-poppins font-bold text-blue-500 group-hover:visible">
                Less about Raman
              </h1>
            </div>
            <div className="group relative col-span-1 m-4 flex flex-col rounded-2xl border border-solid border-[#EAF0FF]">
              <div className="flex content-center items-center justify-center">
                <img src={wonCee} width="100%" className="object-cover object-top" style={{ height: "450px" }} alt="" />
              </div>
              <div className="rounded-b-2xl text-center md:px-4 md:text-left">
                <h1 className="text-yankessblue px-12 py-1 font-poppins text-[20px] leading-[28px] md:px-0">
                  <b>Tim Steele</b>
                </h1>
                <p className="text-yankessblue py-1 font-poppins text-[14px] leading-[28px] opacity-50 md:text-[16px] lg:text-[18px] xl:text-[20px]">
                  Chief Operating Officer
                </p>
              </div>
              <div
                className="invisible absolute top-0 left-0 w-full bg-white p-2 group-hover:visible group-hover:transition-all"
                style={{ height: "calc(100% - 60px)" }}
              >
                <p className="h-full overflow-y-scroll text-lg">
                  In his career, Tim has held such positions as Vice President of Business Development, Director of
                  North American Sales & Marketing, and Chief Strategy Officer for various B2B service and tech
                  companies. Over a fifteen-year period as Managing Partner at Caxton Growth Partners, Tim led marketing
                  efforts for such clients as Capgemini, CSC, IBM, and Fujitsu. Tim has a rare blend of experience in
                  both sales management and marketing strategy. Tim is the proud father of two and proud grandfather of
                  three.
                </p>
              </div>
              {/*  Yes by default and no on group-hover */}
              <h1 className="visible bottom-0 left-4 mt-auto px-4 py-4 font-poppins font-bold text-blue-500 group-hover:visible group-hover:invisible">
                More about Tim
              </h1>
              <h1 className="invisible absolute bottom-0 left-4 py-4 font-poppins font-bold text-blue-500 group-hover:visible">
                Less about Tim
              </h1>
            </div>
          </div>
        </div>

        {/* -------------------------------------------- Section Explore ------------------------------------------------ */}
      </div>

      <Explore />
      {/* -------------------------------------------- Footer ------------------------------------------------ */}
      <Footer />
    </div>
  );
};
