import React, { useEffect } from "react";
import logo from "../../assets/equitek-logo.svg";
import menu from "../../assets/menu-outline.svg";
import menuMinimize from "../../assets/menu-minimize.svg";
import menuProduct from "../../assets/menu-product.svg";
import menuAboutUs from "../../assets/menu-aboutus.svg";
import menuAnalytics from "../../assets/menu-analytics.svg";
import menuResources from "../../assets/menu-resources.svg";
import menuIntegrations from "../../assets/menu-integrations.svg";
import menuWorkforce from "../../assets/menu-workforce-management.svg";
import menuMyShiftHealth from "../../assets/menu-myshifthealth.svg";
import caseStudies from "../../assets/product-myshift-case-studies.svg";
import health from "../../assets/product-myshift-health.svg";
import faq from "../../assets/product-myshift-faq.svg";
import helpCenter from "../../assets/product-myshift-help-center.svg";
import close from "../../assets/close-icon.svg";
import { Link } from "react-router-dom";
import { paths } from "../../constants";

const Product = (): JSX.Element => {
  const path = window.location.pathname;
  const [hover, setHover] = React.useState({ product: false });

  const [width, setWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const ref = React.useRef(null);
  const left = (ref as any)?.current?.offsetLeft;
  const leftMargin = left < 475 && width - 900 > 0 ? -((width - 900 + (left as number)) / 2) : -450;

  return (
    <li
      ref={ref}
      className={`relative mr-4 cursor-pointer p-2 font-poppins text-xl sm:mr-2 md:px-4 ${
        path === paths.myshift ? "font-bold" : "font-normal"
      } text-darkliver`}
      onMouseEnter={() => setHover({ product: true })}
    >
      Product
      {hover.product && (
        <div>
          <div className="absolute top-[100%] left-[45%]">
            <div
              style={{ clipPath: "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)" }}
              className="h-[32px] w-[32px] bg-white "
            ></div>
          </div>
          <div
            className={`border-gray absolute top-[60px] left-[50%] z-50 w-[900px] rounded-2xl bg-white shadow-lg`}
            style={{ marginLeft: `${leftMargin}px` }}
            onMouseLeave={() => setHover({ product: false })}
          >
            {/*  Card */}
            <div className="grid h-full grid-cols-3 p-8">
              <div className="col-span-1 flex flex-col justify-evenly p-4">
                <h1 className="font-poppins text-[32px] font-medium text-[#202020]">Product</h1>
                <h2 className="font-poppins text-[14px] font-normal text-[#707070]">
                  Learn more about our game-changing, innovative software and workforce planning platform built for
                  facilities by facility operators, centered around patients and healthcare professionals.
                </h2>
              </div>
              <div className="col-span-1 p-4">
                <h1 className="font-poppins text-[12px] font-semibold text-[#97A3B7]">Resources</h1>
                <div className="flex flex-col justify-evenly">
                  <div className="mt-4">
                    <Link to={paths.myshift}>
                      <img src={health} alt="health" />
                      <h2 className="mt-2 font-poppins font-normal text-[#111B29]">MyShiftHealth</h2>
                      <h3 className="mt-1 font-poppins text-sm font-medium">Learn how to integrate</h3>
                    </Link>
                  </div>
                  <div className="mt-8">
                    <Link to={paths.analytics}>
                      <img src={helpCenter} alt="logo" />
                      <h2 className="mt-2 font-poppins font-normal text-[#111B29]">Analytics & Reporting</h2>
                      <h3 className="mt-1 font-poppins text-sm font-medium">All about how to use</h3>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-span-1 p-4">
                <h1 className="font-poppins text-[12px] font-semibold text-[#97A3B7]">Best practices</h1>
                <div className="flex flex-col justify-evenly">
                  <div className="mt-4">
                    <Link to={paths.workforceManagement}>
                      <img src={faq} alt="health" />
                      <h2 className="mt-2 font-poppins font-normal text-[#111B29]">Workforce Management</h2>
                      <h3 className="mt-1 font-poppins text-sm font-medium">Everything you need to know</h3>
                    </Link>
                  </div>
                  <div className="mt-8">
                    <Link to={paths.integration}>
                      <img src={caseStudies} alt="logo" />
                      <h2 className="mt-2 font-poppins font-normal text-[#111B29]">Integrations</h2>
                      <h3 className="mt-1 font-poppins text-sm font-medium">How our products help you</h3>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </li>
  );
};

export const NavBar = ({ className = "" }: { className?: string }): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isProductOpen, setIsProductOpen] = React.useState(false);

  const path = window.location.pathname;

  return (
    <div className={`flex flex-row-reverse items-center py-4 sm:flex-row ${className} z-50`}>
      <img src={menu} alt="menu" width="15%" className="cursor-pointer md:hidden" onClick={() => setIsMenuOpen(true)} />
      <div className="mr-auto flex items-center sm:ml-0">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>

      <nav className="ml-auto hidden md:block">
        <ul className="flex">
          <Product />

          <li className={`cursor-pointer p-2 font-poppins text-xl font-normal text-darkliver sm:mr-2 md:mr-4 md:px-4`}>
            Resources
          </li>
          <Link to={paths.aboutUs}>
            <li
              className={`cursor-pointer p-2 font-poppins text-xl sm:mr-2 md:mr-4 md:px-4 ${
                path === paths.aboutUs ? "font-bold" : "font-normal"
              } text-darkliver`}
            >
              About Us
            </li>
          </Link>
          <Link to={paths.contactUs}>
            <li
              className={`cursor-pointer rounded-full border border-solid border-ultramarinblue p-2 font-poppins text-xl font-medium leading-[30px] text-ultramarinblue sm:mr-2 md:mr-4 md:px-4`}
            >
              Contact Us
            </li>
          </Link>
          {/* <li
            className={`cursor-pointer rounded-full bg-ultramarinblue p-2 font-poppins text-xl font-medium text-white sm:mr-2 md:px-4`}
          >
            Login
          </li> */}
        </ul>
      </nav>

      {/* -----------------------------------------------Menu----------------------------------------------------- */}
      <div
        className={`fixed top-0 left-0 z-50 flex h-full w-[90%] flex-col overflow-scroll bg-white p-8 ${
          isMenuOpen ? "flex" : "hidden"
        }`}
      >
        <div className="flex items-center">
          <h1 className="font-poppins text-3xl font-bold text-darkliver">Menu</h1>
          <button className="ml-auto" onClick={() => setIsMenuOpen(false)}>
            <img src={close} alt="close" className="w-[64px]" />
          </button>
        </div>
        <ul className="mt-4">
          <li
            className={`cursor-pointer py-4 font-poppins text-2xl font-normal`}
            onClick={() => setIsProductOpen(!isProductOpen)}
          >
            <div className="flex items-center">
              <img src={menuProduct} alt="home" className="mr-4" />
              Product
              <img src={menuMinimize} className="ml-auto mr-4 w-[32px]" />
            </div>
            {isProductOpen && (
              <div className="ml-8">
                {[
                  { name: "MyShiftHealth", icon: menuMyShiftHealth, link: paths.myshift },
                  { name: "Workforce Management", icon: menuWorkforce, link: paths.workforceManagement },
                  { name: "Analytics & Reporting", icon: menuAnalytics, link: paths.analytics },
                  { name: "Integrations", icon: menuIntegrations, link: paths.integration },
                ].map((item, index) => (
                  <Link to={item.link} key={index} className="p-8">
                    <div className="flex items-center">
                      <img src={item.icon} alt={item.name} className="mr-4" />
                      {item.name}
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </li>

          <li className="py-4 font-poppins text-2xl">
            <div className="flex items-center">
              <img src={menuResources} alt="home" className="mr-4" />
              Resources
            </div>
          </li>
          <Link to={paths.aboutUs}>
            <li className={`py-4 font-poppins text-2xl ${path === paths.aboutUs ? "font-bold" : "font-normal"}`}>
              <div className="flex items-center">
                <img src={menuAboutUs} alt="home" className="mr-4" />
                About Us
              </div>
            </li>
          </Link>
        </ul>
        <div className="mt-auto">
          <ul>
            <Link to={paths.contactUs}>
              <li
                className={`mt-4 cursor-pointer rounded-full border border-solid border-ultramarinblue py-2 px-4 text-center font-poppins text-xl font-medium leading-[30px] text-ultramarinblue`}
              >
                Contact Us
              </li>
            </Link>
            {/* <li
              className={`mt-4 cursor-pointer rounded-full bg-ultramarinblue py-2 px-6 text-center font-poppins text-xl font-medium text-white`}
            >
              Login
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};
