// Footer
import React from "react";
import logo from "../../assets/equitek-logo.svg";
import appleStore from "../../assets/link-apple-store.svg";
import playStore from "../../assets/link-play-store.svg";
import { Link } from "react-router-dom";
import { paths } from "../../constants";

export const Footer = (): JSX.Element => {
  return (
    <div className="bg-primary px-8 pt-16 sm:px-12 md:px-16">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-4">
        <div className="col-span-1">
          <div className="flex items-center">
            <img src={logo} alt="logo" />
          </div>
          <p className="mt-4 pr-12 font-poppins font-light text-darkliver">
            The Health Center Program Compliance Manual and other resources to help current and prospective health
            centers understand program requirements.
          </p>
          <div className="mt-8 flex space-x-4">
            
            <a href="https://apps.apple.com/in/app/myshifthealth/id6443498337" target="_blank" rel="noopener noreferrer">
            <img src={appleStore} alt="app store" />
            </a>            
            <a href="https://play.google.com/store/apps/details?id=com.myshift" target="_blank" rel="noopener noreferrer">
            <img src={playStore} alt="play store" />
            </a>
          </div>
        </div>
        <div className="col-span-1 sm:ml-6">
          <h1 className="mt-4 mb-2 font-poppins font-bold text-darkliver sm:mb-2">Company</h1>
          <ul>
            <li className="font-poppins font-light leading-[30px] text-darkliver sm:leading-[45px]">
              <Link to={paths.aboutUs}>About Us</Link>
            </li>
            <li className="font-poppins font-light leading-[30px] text-darkliver sm:leading-[45px]">Newsroom</li>
            <li className="font-poppins font-light leading-[30px] text-darkliver sm:leading-[45px]">Careers</li>
            <li className="font-poppins font-light leading-[30px] text-darkliver sm:leading-[45px]">
              <Link to={paths.contactUs}>Contact and Support</Link>
            </li>
            <li className="font-poppins font-light leading-[30px] text-darkliver sm:leading-[45px]">
              info@equitek.net
            </li>
          </ul>
        </div>
        <div className="col-span-1 sm:ml-6">
          <h1 className="mt-4 mb-2 font-poppins font-bold text-darkliver sm:mb-2">Solutions</h1>
          <ul>
            <li className="font-poppins font-light leading-[30px] text-darkliver sm:leading-[45px]">
              <Link to={paths.myshift}>MyShift Health</Link>
            </li>
            <li className="font-poppins font-light leading-[30px] text-darkliver sm:leading-[45px]">
              <Link to={paths.workforceManagement}>Workforce Management</Link>
            </li>
            <li className="font-poppins font-light leading-[30px] text-darkliver sm:leading-[45px]">
              <Link to={paths.analytics}>Analytics & Reporting</Link>
            </li>
            <li className="font-poppins font-light leading-[30px] text-darkliver sm:leading-[45px]">
              <Link to={paths.integration}>Integrations</Link>
            </li>
          </ul>
        </div>
        <div className="col-span-1 sm:ml-6">
          <h1 className="mt-4 mb-2 font-poppins font-bold text-darkliver sm:mb-2">Resources</h1>
          <ul>
            <li className="font-poppins font-light leading-[30px] text-darkliver sm:leading-[45px]">Why Equitek</li>
            <li className="font-poppins font-light leading-[30px] text-darkliver sm:leading-[45px]">
              Value Calculator
            </li>
            <li className="font-poppins font-light leading-[30px] text-darkliver sm:leading-[45px]">Blog</li>
            <li className="font-poppins font-light leading-[30px] text-darkliver sm:leading-[45px]">
              <Link to={paths.privacyPolicy}>Privacy Policy</Link>
            </li>
            <li className="font-poppins font-light leading-[30px] text-darkliver sm:leading-[45px]">
              <Link to={paths.termsAndConditions}>Terms & conditions</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full py-8 text-center font-poppins">Copyright Equitek 2023</div>
    </div>
  );
};
