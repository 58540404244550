export const paths = {
  home: "/",
  myshift: "/myshift",
  myshifthealth: "https://www.myshifthealth.com/",
  aboutUs: "/about",
  integration: "/integration",
  workforceManagement: "/workforce-management",
  analytics: "/analytics",
  contactUs: "/contact",
  privacyPolicy: "/privacy-policy",
  termsAndConditions: "/terms-and-conditions",
};
