import { NavBar } from "../../Common/NavBar";
import { Explore } from "../../Common/Explore";
import { Footer } from "../../Common/Footer";
import React, { useEffect, useState } from "react";
import background from "../../../assets/product-analytics/background.svg";
import cancerFree from "../../../assets/product-analytics/cancer-free.svg";
import bookNow from "../../../assets/product-analytics/book-now.svg";
import metrics from "../../../assets/product-analytics/metrics.svg";
import metrics2 from "../../../assets/product-analytics/metrics-2.svg";
import views from "../../../assets/product-analytics/views.jpg";
import heroImage from "../../../assets/product-analytics/hero-image.svg";

const Section1 = (): JSX.Element => {
  return (
    <div className="px-4 sm:px-8 lg:px-32">
      <div className="mt-16 grid grid-cols-1 md:grid-cols-2">
        <div className="order-2 col-span-1 mt-20 flex flex-col justify-center md:order-none md:mt-0">
          <h1 className="font-poppins text-3xl font-bold text-ultramarinblue">Analytics & Reporting</h1>
          <p className="mt-8 pb-8 font-poppins text-2xl font-light leading-[36px] text-darkliver md:pb-0">
            Equitek provides advanced analytics and reporting solutions to power control tower data insight and
            real-time operations management
          </p>
        </div>
        <div className="relative col-span-1 flex items-start justify-center">
          <div className="absolute left-[8%] top-[10%] sm:left-[20%] md:left-[8%] lg:left-[24%]">
            <div className="absolute h-[400px] w-[325px] rounded-full bg-white md:h-[400px] md:w-[325px]" />
            <div className="absolute h-[400px] w-[325px] scale-110 rounded-full border border-white bg-transparent md:h-[400px] md:w-[325px]" />
            <div className="absolute h-[400px] w-[325px] scale-125 rounded-full border border-white bg-transparent md:h-[400px] md:w-[325px]" />
          </div>
          <img
            src={heroImage}
            alt="hero image"
            className="z-30 mt-8 scale-90 md:-mt-20 md:scale-95 lg:-mr-12 lg:scale-95"
          />
        </div>
      </div>
    </div>
  );
};

const Section2 = (): JSX.Element => {
  return (
    <div className="app standard-container mt-16">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="relative col-span-1 flex flex-row items-center pb-12">
          <img src={metrics} alt="background image 1" className="sm:scale-75" />
        </div>
        <div className="col-span-1 flex items-center pb-4">
          <div className="flex md:p-8">
            <div className="px-4">
              <div className="w-24 border-4 border-[#E74040]"></div>
              <h1 className="mt-4 font-poppins text-[32px] font-semibold leading-[40px]">Metrics</h1>
              <p className="mt-4 font-poppins text-[20px] font-light leading-[30px] text-darkliver">
                Delivering lower cost Ready to reduce staffing premium cost? Finding, recruiting and retaining
                healthcare professionals is particularly challenging and costly to facilities. Leverage the
                MyShiftHealth platform capabilities to regain control over the cost and process of filling open shifts.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-16 grid grid-cols-1 md:grid-cols-2">
        <div className="order-2 col-span-1 flex items-center pb-4 md:order-none">
          <div className="flex md:p-8">
            <div className="px-4">
              <div className="w-24 border-4 border-[#E74040]"></div>
              <h1 className="mt-4 font-poppins text-[32px] font-semibold leading-[40px]">Views</h1>
              <p className="mt-4 font-poppins text-[20px] font-light leading-[30px] text-darkliver">
                Connect with our integration partners for automated employee data transfer, faster and convenient
                payment, and real-time scheduling and staffing.
              </p>
            </div>
          </div>
        </div>
        <div className="relative col-span-1 flex flex-row items-center justify-center pb-12">
          <img src={views} alt="background image 1" className="sm:scale-75" />
        </div>
      </div>
      <div className="mt-16 grid grid-cols-1 md:grid-cols-2">
        <div className="relative col-span-1 flex flex-row items-center pb-12">
          <div className="mx-4 grow p-8 shadow-lg md:mx-8">
            <img src={cancerFree} alt="background image 1" className="" />
            <h1 className="mt-8 font-bold">Cancer Care</h1>
            <div className="mt-4 w-24 border border-[#E74040]"></div>
            <p className="mt-4">The gradual accumulation of information about </p>
          </div>
          <div className="mx-4 mt-48 grow p-8 shadow-lg md:mx-8">
            <img src={bookNow} alt="background image 1" className="" />
            <h1 className="mt-8 font-bold">Book now</h1>
            <div className="mt-4 w-24 border border-[#E74040]"></div>
            <p className="mt-4">The gradual accumulation of information about</p>
          </div>
        </div>
        <div className="col-span-1 flex items-center pb-4">
          <div className="flex md:p-8">
            <div className="px-4">
              <div className="w-24 border-4 border-[#E74040]"></div>
              <h1 className="mt-4 font-poppins text-[32px] font-semibold leading-[40px]">Dashboards</h1>
              <p className="mt-4 font-poppins text-[20px] font-light leading-[30px] text-darkliver">
                Connect with our integration partners for automated employee data transfer, faster and convenient
                payment, and real-time scheduling and staffing.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mt-16 grid grid-cols-1 md:grid-cols-2">
        <div className="order-2 col-span-1 flex items-center pb-4 md:order-none">
          <div className="flex md:p-8">
            <div className="px-4">
              <div className="w-24 border-4 border-[#E74040]"></div>
              <h1 className="mt-4 font-poppins text-[32px] font-semibold leading-[40px]">Metrics</h1>
              <p className="mt-4 font-poppins text-[20px] font-light leading-[30px] text-darkliver">
                Delivering lower cost Ready to reduce staffing premium cost? Finding, recruiting and retaining
                healthcare professionals is particularly challenging and costly to facilities. Leverage the
                MyShiftHealth platform capabilities to regain control over the cost and process of filling open shifts.
              </p>
            </div>
          </div>
        </div>
        <div className="relative col-span-1 flex flex-row items-center justify-center pb-12 ">
          <img src={metrics2} alt="background image 1" className="sm:scale-75" />
        </div>
      </div> */}
    </div>
  );
};
export const Analytics = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return (
    <div>
      <div
        style={{
          background: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <NavBar className="app standard-container bg-transparent" />
        <Section1 />
      </div>
      <Section2 />
      <Explore />
      <Footer />
    </div>
  );
};
