import React from "react";
import { NavBar } from "../../Common/NavBar";
import Person1 from "../../../assets/product-myshift/hero-image.svg";
import Icon from "../../../assets/common/product-page-myshift-icon.svg";
import Info1 from "../../../assets/product-myshift/product-page-info-1.svg";
import Info2 from "../../../assets/product-myshift/product-page-info-2.svg";
import Info3 from "../../../assets/product-myshift/product-page-info-3.svg";
import Info4 from "../../../assets/product-myshift/product-page-info-4.png";
import { Explore } from "../../Common/Explore";
import { Footer } from "../../Common/Footer";
import { Right } from "../../Common/Right";

import { useNavigate } from "react-router-dom";
import { paths } from "../../../constants";


const Section1 = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div className="app standard-container">
      <div className="md:grid md:grid-cols-5 lg:mt-16 lg:px-0">
        <div className="order-2 pb-16 md:col-span-2 md:mt-24 lg:mt-12 2xl:mt-0">
          <img src={Person1} className="m-auto w-[70%] sm:ml-auto sm:w-[45%] md:w-[80%] lg:w-[90%]" alt="" />
        </div>
        <div className="sm:mr-auto md:col-span-3">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <div>
              <img src={Icon} className="mx-auto mt-12 w-[100vw] sm:text-left md:mx-0 md:w-[20vw] lg:w-[20vw]" alt="" />
            </div>
            <div className="mt-16">
              <button
                onClick={() => {
                  window.open(paths.myshifthealth, '_blank');
                }}
                className="mx-4 w-full cursor-pointer rounded-full bg-ultramarinblue py-2 px-4 font-poppins text-xl font-medium text-white md:ml-8 md:w-auto"
              >
                Launch MyShiftHealth
              </button>
            </div>
          </div>
          <div className="mt-12 text-center font-poppins font-bold leading-[150%]  sm:text-left md:pr-16 lg:pr-20">
            <Right />
          </div>
          <div className="mt-12 grid grid-cols-1 gap-4 px-8 md:grid-cols-4 md:px-0 lg:px-0">
            <div style={{ border: "1px solid #EAF0FF" }} className="col-span-2 rounded-2xl p-8">
              <h1 className="font-poppins text-xl font-semibold">Match</h1>
              <h2 className="font-poppins leading-6 text-aurometalsaurus">
                facilities directly with today’s workforce on-demand
              </h2>
            </div>
            <div style={{ border: "1px solid #EAF0FF" }} className="col-span-2 rounded-2xl p-8">
              <h1 className="font-poppins text-xl font-semibold">Schedule</h1>
              <h2 className="font-poppins leading-6 text-aurometalsaurus">
                internal and external clinicians when and where they want to work
              </h2>
            </div>
            <div style={{ border: "1px solid #EAF0FF" }} className="col-span-2 col-start-1 rounded-2xl p-8">
              <h1 className="font-poppins text-xl font-semibold">Reduce Cost</h1>
              <h2 className="font-poppins leading-6 text-aurometalsaurus">
                associated with open shifts and loss of patient volume
              </h2>
            </div>
            <div style={{ border: "1px solid #EAF0FF" }} className="col-span-2 rounded-2xl p-8">
              <h1 className="font-poppins text-xl font-semibold">Increase Revenue</h1>
              <h2 className="font-poppins leading-6 text-aurometalsaurus">
                associated with open shifts and loss of patient volume
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Section2 = (): JSX.Element => {
  return (
    <div className="mt-16">
      <div className="grid grid-cols-1 gap-24">
        <div className="col-span-1 bg-primary ">
          <div className="px-12 md:px-8 lg:px-32">
            <div className="px-4 py-8 md:flex md:flex-row-reverse">
              <div className="px-8">
                <h1 className="text-center font-poppins text-3xl font-bold leading-10 md:text-left">
                  On-demand & predictive matching
                </h1>
                <h2 className="font mt-4 text-center font-poppins text-sm md:text-left">
                  Flexible, timely, efficient, and expansive
                </h2>
                <h2 className="mt-4 hidden font-poppins text-xl md:block md:text-left">
                  Staff the way you want, when you want, how you want-reach a talent pool that stretches beyond your
                  facility or health system. Let our proprietary tech-enabled platform automatically forecast demand and
                  supply of clinicians, and fill shifts to scale.
                </h2>
              </div>
              <img src={Info1} className="mx-auto mt-12 w-[100vw] p-4 md:mt-0 md:w-[30vw] md:p-8" alt="" />
              <h2 className="mt-4 font-poppins text-xl md:hidden">
                Staff the way you want, when you want, how you want-reach a talent pool that stretches beyond your
                facility or health system. Let our proprietary tech-enabled platform automatically forecast demand and
                supply of clinicians, and fill shifts to scale.
              </h2>
            </div>
          </div>
        </div>

        <div className="px-12">
          <div className="px-4 md:px-8 lg:px-32">
            <div className="col-span-1 md:flex">
              <div className="px-8">
                <h1 className="text-center font-poppins text-3xl font-bold leading-10 md:text-left">
                  Dynamic Scheduling
                </h1>
                <h2 className="font mt-4 text-center font-poppins text-sm md:text-left">Tired of manual scheduling</h2>
                <h2 className="mt-4 hidden font-poppins text-xl md:block md:text-left">
                  Use our AI-enabled scheduling software to eliminate manual processes to post, create, distribution, or
                  change schedules. Allow your teams to focus on providing quality patient care.
                </h2>
              </div>
              <img src={Info2} className="mx-auto mt-12 w-[100vw] p-4 md:mt-0 md:w-[30vw] md:p-8" />
              <h2 className="mt-4 font-poppins text-xl md:hidden">
                Use our AI-enabled scheduling software to eliminate manual processes to post, create, distribution, or
                change schedules. Allow your teams to focus on providing quality patient care.
              </h2>
            </div>
          </div>
        </div>
        <div className="px-12">
          <div className="px-4 md:px-8 lg:px-32">
            <div className="col-span-1 flex-row-reverse md:flex">
              <div className="px-8">
                <h1 className="text-center font-poppins text-3xl font-bold leading-10 md:text-left">
                  Delivering lower cost
                </h1>
                <h2 className="font mt-4 text-center font-poppins text-sm  md:text-left">
                  Ready to reduce staffing premium cost?
                </h2>
                <h2 className="mt-4 hidden font-poppins text-xl md:block md:text-left">
                  Finding, recruiting and retaining healthcare professionals is particulalry challening and costly to
                  facilities. Leverage the MyShiftHealth platform capabilities to regain control over the cost and
                  process of filling open shifts.
                </h2>
              </div>
              <img src={Info3} className="mx-auto mt-12 w-[100vw] p-4 md:mt-0 md:w-[25vw] md:p-8" />
              <h2 className="mt-4 font-poppins text-xl md:hidden">
                Finding, recruiting and retaining healthcare professionals is particulalry challening and costly to
                facilities. Leverage the MyShiftHealth platform capabilities to regain control over the cost and process
                of filling open shifts.
              </h2>
            </div>
          </div>
        </div>
        <div className="px-12">
          <div className="px-4 md:px-8 lg:px-32">
            <div className="col-span-1 md:flex">
              <div className="px-8">
                <h1 className="text-center font-poppins text-3xl font-bold leading-10 md:text-left">
                  Improving bottom line
                </h1>
                <h2 className="font mt-4 text-center font-poppins text-sm  md:text-left">
                  Protect key revenue streams
                </h2>
                <h2 className="mt-4 hidden font-poppins text-xl md:block md:text-left">
                  Build confidence to staff key, revenue-generating and patient-facing resources. Ensure revenue
                  optimisation and delivery quality care consistently.
                </h2>
              </div>
              <img src={Info4} className="mx-auto mt-12 w-[100vw] p-4 md:mt-0 md:w-[30vw] md:p-8" />
              <h2 className="mt-4 font-poppins text-xl md:hidden">
                Build confidence to staff key, revenue-generating and patient-facing resources. Ensure revenue
                optimisation and delivery quality care consistently.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MyShift = (): JSX.Element => {
  return (
    <div>
      <div>
        <div className="absolute top-0 right-0 -z-10 h-[60%] w-[100vw] rounded-bl-[16rem] bg-primary md:h-[70%] md:h-[80%] md:w-[45%] 2xl:h-[90%] 2xl:w-[45%]"></div>
        <NavBar className="app standard-container bg-transparent" />
        <Section1 />
        <Section2 />
      </div>
      <Explore />

      <Footer />
    </div>
  );
};
