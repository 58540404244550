import { NavBar } from "../../Common/NavBar";
import { Explore } from "../../Common/Explore";
import { Footer } from "../../Common/Footer";
import React from "react";
import heroImage from "../../../assets/product-workforce/hero-image.jpg";
import bgArt1 from "../../../assets/product-workforce/bg-art1.svg";
import bgArt2 from "../../../assets/product-workforce/bg-art2.svg";
import feature1 from "../../../assets/product-workforce/feature1.svg";
import feature2 from "../../../assets/product-workforce/feature2.svg";
import featureIcon1 from "../../../assets/product-workforce/feature-icon-1.svg";
import featureIcon2 from "../../../assets/product-workforce/feature-icon-2.svg";
import middleman from "../../../assets/about-us-middleman.svg";
import reduceCost from "../../../assets/about-us-reduce-cost.svg";
import improve from "../../../assets/about-us-improve.svg";
import deliver from "../../../assets/about-us-deliver.svg";
import image1 from "../../../assets/product-myshift/1.svg";
import image2 from "../../../assets/product-myshift/2.svg";
import image3 from "../../../assets/product-myshift/3.svg";
import image4 from "../../../assets/product-myshift/4.svg";

const ReviewCard = (): JSX.Element => {
  return (
    <div
      className="relative flex flex-col items-start justify-center bg-white pl-2"
      style={{ border: "5px solid rgba(28, 181, 189, 0.5)", borderRadius: "5px", height: "140px", width: "240px" }}
    >
      <div className="flex">
        <img src={image1} alt="Avatar" className="-ml-[0%] h-12 w-12 shrink rounded-full " />
        <img src={image2} alt="Avatar" className="-ml-[8%] h-12 w-12 shrink rounded-full " />
        <img src={image3} alt="Avatar" className="-ml-[8%] h-12 w-12 shrink rounded-full " />
        <img src={image4} alt="Avatar" className="-ml-[8%] h-12 w-12 shrink rounded-full " />
        <div className="-ml-[8%] flex h-12 w-12 shrink items-center justify-center rounded-full bg-[#1CB5BD] text-xs font-semibold text-white">
          100+
        </div>
      </div>

      <h1 className="mt-2 font-poppins text-xl font-semibold">120+ patients</h1>
      <div className="mt-0 flex items-center justify-center">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8 11.2L5.12 13.44L5.84 10.08L3.2 7.68L6.48 7.36L8 4L9.52 7.36L12.8 7.68L10.16 10.08L10.88 13.44L8 11.2Z"
            fill="#FFCE31"
          />
          <path
            d="M8 11.2L5.12 13.44L5.84 10.08L3.2 7.68L6.48 7.36L8 4L9.52 7.36L12.8 7.68L10.16 10.08L10.88 13.44L8 11.2Z"
            stroke="#FFCE31"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <h1 className="pl-1 font-poppins text-darkliver">5.0(450k reviews)</h1>
      </div>
    </div>
  );
};

const Section1 = (): JSX.Element => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const handleResize = (): void => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  // get the width of the div
  const [width, setWidth] = React.useState(0);
  React.useEffect(() => {
    console.log(ref.current?.offsetWidth);
    setWidth(ref.current?.offsetWidth ?? 0);
  });

  return (
    <div className="app standard-container">
      <div className="mt-16 grid grid-cols-1 gap-8 md:grid-cols-2">
        <div className="order-2 col-span-1 mt-12 md:order-none md:mt-0">
          <h1 className="font-poppins text-[44px] font-bold text-ultramarinblue">Workforce Management</h1>
          <h2 className="mt-8 font-poppins text-3xl leading-[60px]">
            Workforce management solutions engineered to tackle facilities, staffing and scheduling challenges{" "}
          </h2>
          <p className="mt-8 font-poppins text-2xl font-light leading-[36px] text-darkliver">
            From ambulatory surgery centres to multi-state health systems, we help facilities throughout North America
            staff and manage workforce.
          </p>
        </div>
        <div className="relative order-1 col-span-1 md:order-none md:h-auto" ref={ref}>
          <div className="z-30 mx-auto" style={{ width: `${width / (isMobile ? 1.2 : 1.2)}px`, position: "inherit" }}>
            <img src={heroImage} alt="hero image" className="z-40" />
          </div>
          <div
            className="md: absolute left-1/3 right-0 top-1/3 mx-auto md:top-[20%]"
            style={{ width: `${width / (isMobile ? 1.2 : 1.2)}px` }}
          >
            <img src={bgArt1} alt="background image line" className="-z-10" />
          </div>
          <div
            className="absolute left-0 right-0 top-5 mx-auto scale-90"
            style={{ width: `${width / (isMobile ? 1.2 : 1.2)}px` }}
          >
            <img src={bgArt2} alt="background image green" />
          </div>

          {/* <div className="absolute right-0 -bottom-[10%] z-30 md:bottom-[35%] lg:bottom-[20%]">
            <ReviewCard />
          </div> */}
        </div>
      </div>
    </div>
  );
};

const Section2 = (): JSX.Element => {
  return (
    <div className="mt-16 bg-[#E9F7F8] ">
      <div className="grid grid-cols-1 content-center md:grid-cols-2">
        <div className="relative col-span-1 h-[85vh] overflow-hidden md:h-auto">
          <div className="absolute -left-24 bottom-8 right-0 mx-auto ">
            <img src={feature1} alt="background image 1" className="rotate-[56deg] scale-75" />
          </div>
          <div className="absolute left-[20rem] bottom-28 mx-auto">
            <img src={feature2} alt="background image 1" className="scale-90" />
          </div>
          <div className="absolute left-12 bottom-12 mx-auto">
            <div className="mb-16 w-full rounded-2xl bg-white shadow-lg">
              <div className="flex min-w-[240px] flex-col items-start gap-2 p-4">
                <h1 className="font-poppins text-[28px] font-semibold leading-[40px] text-black">Emergency</h1>
                <button className="w-full rounded-xl bg-[#1CB5BD] p-2 text-left font-poppins text-[20px] font-semibold text-white">
                  Consultation
                </button>
                <h1 className="font-poppins text-[28px] font-semibold leading-[40px] text-black">Queue</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1 flex flex-col justify-center pb-4 ">
          <div className="flex flex-col p-8 md:flex-row">
            <div className="flex h-[5rem] w-[5rem] shrink-0 items-center justify-center rounded-full bg-white">
              <img src={featureIcon1} alt=" " className="h-[2rem] w-[2rem]" />
            </div>
            <div className="mt-8 md:ml-8 md:mt-0">
              <h1 className="font-poppins text-[24px] font-semibold leading-[30px]">Key Features</h1>
              <ul className="ml-5 mt-2">
                <li className="list-disc font-poppins text-[20px] font-light leading-[40px] text-darkliver">
                  Workforce management and financial metrics
                </li>
                <li className="list-disc font-poppins text-[20px] font-light leading-[40px] text-darkliver">
                  Operational and analytical dashboards
                </li>
                <li className="list-disc font-poppins text-[20px] font-light leading-[40px] text-darkliver">
                  Supply (Facility) and demand (professional) side views
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col p-8 md:flex-row">
            <div className="flex h-[5rem] w-[5rem] shrink-0 items-center justify-center rounded-full bg-white">
              <img src={featureIcon2} alt=" " className="h-[2rem] w-[2rem]" />
            </div>
            <div className="mt-8 md:ml-8 md:mt-0">
              <h1 className="font-poppins text-[24px] font-semibold leading-[30px]">Key Capabilities</h1>
              <ul className="ml-5 mt-2">
                <li className="list-disc font-poppins text-[20px] font-light leading-[40px] text-darkliver">
                  Real-time visibility and exception- based monitoring
                </li>
                <li className="list-disc font-poppins text-[20px] font-light leading-[40px] text-darkliver">
                  Real-time notification and collaboration
                </li>
                <li className="list-disc font-poppins text-[20px] font-light leading-[40px] text-darkliver">
                  Tech-enabled decision support and in-platform execution of recommendations
                </li>
                <li className="list-disc font-poppins text-[20px] font-light leading-[40px] text-darkliver">
                  AI-enabled decision making
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Section3 = (): JSX.Element => {
  return (
    <div className="app standard-container">
      <div className="mt-12 grid grid-cols-1 gap-12 md:grid-cols-2 ">
        <div className="col-span-1 rounded-2xl border border-solid border-[#EAF0FF] md:grid md:grid-cols-5">
          <div className="flex flex-col content-center items-center justify-center rounded-t-2xl bg-[#DBEFFA] py-8 md:col-span-2 md:rounded-none md:rounded-l-2xl">
            <img src={reduceCost} alt="" />
          </div>
          <div className="rounded-b-2xl py-8 text-center md:col-span-3 md:px-4 md:text-left">
            <h1 className="text-yankessblue px-12 py-4 font-poppins text-[20px] leading-[28px] md:px-0">
              <b>Staffing</b>
            </h1>
            <p className="text-yankessblue py-4 font-poppins text-[14px] leading-[28px] opacity-50 md:text-[16px] lg:text-[18px] xl:text-[20px]">
              MyShiftHealth, powered by Equitek, provides comprehensive and expansive access to pre-qualified talent
              pool real-time.
            </p>
          </div>
        </div>
        <div className="col-span-1 rounded-2xl border border-solid border-[#EAF0FF] md:grid md:grid-cols-5">
          <div className="flex flex-col content-center items-center justify-center rounded-t-2xl bg-[#FADBE2] py-8 md:col-span-2 md:rounded-none md:rounded-l-2xl ">
            <img src={middleman} alt="" />
          </div>
          <div className="rounded-b-2xl py-8 text-center md:col-span-3 md:px-4 md:text-left">
            <h1 className="text-yankessblue px-12 py-4 font-poppins text-[20px] leading-[28px] md:px-0">
              <b>Scheduling</b>
            </h1>
            <p className="text-yankessblue py-4 font-poppins text-[14px] leading-[28px] opacity-50 md:text-[16px] lg:text-[18px] xl:text-[20px]">
              Effective and customizable scheduling technology-empowering facilities and professionals to staff the
              right clinician, in the right setting, at the right time.
            </p>
          </div>
        </div>

        <div className="col-span-1 rounded-2xl border border-solid border-[#EAF0FF] md:grid md:grid-cols-5">
          <div className="flex flex-col content-center items-center justify-center rounded-t-2xl bg-[#F6DBFA] py-8 md:col-span-2 md:rounded-none md:rounded-l-2xl">
            <img src={improve} alt="" />
          </div>
          <div className="rounded-b-2xl py-8 text-center md:col-span-3 md:px-4 md:text-left">
            <h1 className="text-yankessblue px-12 py-4 font-poppins text-[20px] leading-[28px] md:px-0">
              <b>Operations Management</b>
            </h1>
            <p className="text-yankessblue py-4 font-poppins text-[14px] leading-[28px] opacity-50 md:text-[16px] lg:text-[18px] xl:text-[20px]">
              Optimize Human Resources capability and impact organization-wide:
              <ul className="ml-12 text-left">
                <li className="list-disc">Staffing and scheduling</li>
                <li className="list-disc">Workforce planning</li>
                <li className="list-disc">Risk, Compliance, & payrolling</li>
                <li className="list-disc">Insight and reporting</li>
              </ul>
            </p>
          </div>
        </div>
        <div className="col-span-1 rounded-2xl border border-solid border-[#EAF0FF] md:grid md:grid-cols-5">
          <div className="flex flex-col content-center items-center justify-center rounded-t-2xl bg-[#FADBE2] py-8 md:col-span-2 md:rounded-none md:rounded-l-2xl">
            <img src={deliver} alt="" />
          </div>
          <div className="rounded-b-2xl py-8 text-center md:col-span-3 md:px-4 md:text-left">
            <h1 className="text-yankessblue px-12 py-4 font-poppins text-[20px] leading-[28px] md:px-0">
              <b>AI-enabled</b>
            </h1>
            <p className="text-yankessblue py-4 font-poppins text-[14px] leading-[28px] opacity-50 md:text-[16px] lg:text-[18px] xl:text-[20px]">
              State of the art technology to help facilities to manage, and predict workforce on-demand. While ensuring
              high quality patient care.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WorkforceManagement = (): JSX.Element => {
  return (
    <div>
      <div>
        <NavBar className="app standard-container bg-white" />
      </div>
      <Section1 />
      <Section2 />
      <Section3 />
      <Explore />
      <Footer />
    </div>
  );
};
