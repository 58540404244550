import React from "react";
import { NavBar } from "../Common/NavBar";
import privacyPolicy from "../../assets/privacy-policy.svg";
import "./TermsAndCondition.css";
import { Footer } from "../Common/Footer";

const Section1 = (): JSX.Element => {
  return (
    <div className="standard-container app">
      <div className="grid grid-cols-2 items-center">
        <div className="col-span-1">
          <h1 className="text-5xl font-semibold">Terms & Conditions</h1>
        </div>
        <div className="col-span-1">
          <img src={privacyPolicy} alt="privacy policy" className="scale-75" />
        </div>
      </div>

      <div id="privacy-table" className="mt-16">
        <div>
          <div>
            <h2>Terms & Conditions.</h2>
          </div>
          <div>
            <p>
              <span className="font-semibold">Effective January 1, 2021</span>
            </p>
            <p>
              Equitek LLC (“Equitek”, “we”, or “us”) recognizes the importance of privacy. We want you to feel confident
              using our services, and this privacy notice (“Privacy Notice” or “Notice”) is to help you understand and
              describe how we collect, use, and disclose your information.
            </p>
            <ul>
              <li>
                <p>What information we collect and how it is collected</p>
              </li>
              <li>
                <p>With whom we may share information</p>
              </li>
              <li>
                <p>Legal basis for processing the information</p>
              </li>
              <li>
                <p>Your rights and choices</p>
              </li>
              <li>
                <p>Security and storage of the information</p>
              </li>
              <li>
                <p>Third-party websites</p>
              </li>
              <li>
                <p>Users outside the United States</p>
              </li>
              <li>
                <p>CCPA Compliance</p>
              </li>
              <li>
                <p>Changes to the Notice; and</p>
              </li>
              <li>
                <p>Contacting Equitek</p>
              </li>
            </ul>
            <p>
              Throughout this document, we will use a few terms to describe various products and roles. All of the text,
              data, information, software, graphics, photographs and more that we and our affiliates may make available
              to you, plus our websites and any services, plug-ins, software, or applications (including Equitek Click,
              Equitek for Chrome, Equitek for Firefox, and any Equitek applications) that we may provide all together
              constitute the Equitek “Website.” Equitek “Users” are the people who have created a Equitek account, and
              “Equitek Invitees” are people who have scheduled a meeting with a Equitek User but may or may not have
              registered with Equitek. A Equitek “Viewer” is a person who visits the Equitek Website but may or may not
              be a Equitek User or Equitek Invitee.
            </p>
            <p>
              Any terms defined in the
              <span>
                <a href="/terms">
                  <span className="text-blue-500">Terms of Use</span>
                </a>
              </span>
              ,
              <span>
                <a href="/eula">
                  <span>End User License Agreement</span>
                </a>
              </span>
              , and
              <span>
                <a href="/dpa">
                  <span>Data Processing Addendum</span>
                </a>
              </span>
              and not otherwise defined in this Privacy Notice are hereby adopted by reference in this Privacy Notice.
            </p>
          </div>
        </div>
        <div>
          <div className="col-span-3 mt-16 ">
            <h2>Educational Use.</h2>
          </div>
          <div>
            <p>
              For educational service providers and schools, please see our
              <span>
                <a href="/ferpa">
                  <span>FERPA and COPPA Privacy Policy and Notice</span>
                </a>
              </span>
              .
            </p>
          </div>
        </div>
        <div>
          <div>
            <h2>Information We Collect.</h2>
          </div>
          <div>
            <p>
              We collect information about you directly from you and automatically through your use of our Website. We
              may combine information automatically collected with other information that we have collected about you
              including from publicly available sources.
            </p>
            <p>
              In order to help you protect yourself and your information, we encourage you to provide only that
              information that is necessary for using our Website. For example, to schedule a meeting you may only need
              to provide us with a name, email address, date, and time.
            </p>
            <p>
              Equitek is not directed to children under thirteen (13) years of age and we do not knowingly collect
              Personal Information from children under 13. If we discover that a child under 16 has provided us with
              Personal Information, we will promptly delete such Personal Information from our systems.
            </p>
          </div>
        </div>
        <div>
          <div>
            <h2>Information You Provide Voluntarily.</h2>
          </div>
          <div>
            <p>
              Equitek collects the following information, either directly from Equitek Users, Invitees, or Viewers, or
              through third-parties regarding Users, Invitees, or Viewers.
            </p>
            <h2>Appointment Information.</h2>
            <p>
              A Equitek User, Invitee, or a third-party may voluntarily give us certain information. This can include
              names, email addresses, and phone numbers; email addresses of other people; the subject of the meeting;
              and any other information provided to us or as required by the Equitek User upon scheduling, pursuant to
              the Equitek Terms of Use. If you receive an invitation from a Equitek User but do not wish to become a
              Equitek User, please contact the Equitek User who sent the invitation through other means to set up your
              meeting.
            </p>
            <h2>Calendar Information.</h2>
            <p>
              A Equitek User may connect their calendar with Equitek. Our calendar integration only checks the duration
              and free/busy status of the events in your calendar so that we don’t book you when you’re busy. We never
              store who you are meeting with, their email address, the meeting title, or any other details about the
              appointments in your connected calendar.
            </p>
            <h2>Billing Information.</h2>
            <p>
              If you purchase a premium version of Equitek, our third-party payment processors will collect and store
              your billing address and credit card information. We store the last four digits of your credit card
              number, card type, and the expiration date.
            </p>
          </div>
        </div>
        <div>
          <div>
            <h2>Information Collected Automatically.</h2>
          </div>
          <div>
            <p>
              Like many websites, we and our Service Providers use Cookies, web beacons, and other technologies to
              receive and store certain types of information when you interact with us through your computer or mobile
              device subject to your opt-out preferences (see Your Rights and Choices section below). Using these
              technologies helps us customize your experience with our Website, improve your experience, and tailor
              marketing messages. Here are some of the types of information we collect:
            </p>
            <h2>Log &amp; Device data.</h2>
            <p>
              When you use Equitek, our servers automatically record information (“log data”), including information
              that your browser sends whenever you visit our Website. This log data may include the web address you came
              from or are going to, your device model, operating system, browser type, unique device identifier, IP
              address, mobile network carrier, and time zone or location. Whether we collect some or all of this
              information often depends on what type of device you’re using and its settings. For example, different
              types of information are available depending on whether you’re using a Mac or PC, or an iPhone or an
              Android phone. To learn more about what information your device makes available to us, please check the
              policies of your device manufacturer or software provider.
            </p>
            <h2>Cookie data.</h2>
            <p>
              Depending on how you’re accessing our services and subject to your opt-out preferences, we may use
              “Cookies” (a small text file sent by your computer each time you visit our Website, unique to your Equitek
              account or your browser) or similar technologies to record log data. When we use Cookies, we may use
              ‘session’ Cookies (that last until you close your browser) or ‘persistent’ Cookies (that last until you or
              your browser deletes them). For example, we may use Cookies to keep you logged into Equitek. Some of the
              Cookies we use are associated with your Equitek account (including personal information about you, such as
              the email address you gave us), and other Cookies are not. Equitek provides a centralized cookie
              management service across the entire Equitek application. You can find the link to manage your cookie
              preferences at the bottom of the Equitek homepage.
            </p>
            <h2>Third Party Tools.</h2>
            <p>
              Subject to your opt-out preferences (see ‘Your Rights and Choices’ below), we use third-party Service
              Providers such as Google Analytics to provide certain analytics and Viewer interactions services to
              Equitek in connection with our operation of our Website, including the collection and tracking of certain
              data and information regarding the characteristics and activities of visitors to Equitek. You may opt-out
              of relevant cookies using opt-out features on their respective websites.
            </p>
            <p>
              Equitek may implement Google reCAPTCHA Enterprise to help prevent spam and abuse. reCAPTCHA
              Enterprisecollects hardware and software information, such as device and application data, and sends it to
              Google for purposes of providing, maintaining, and improving reCAPTCHA Enterprise and for general security
              purposes. This information is not used by Google for personalized advertising. Your use of reCAPTCHA
              Enterprise is subject to Google’s
              <span>
                <a href="https://policies.google.com/privacy?hl=en-US">
                  <span>Privacy Policy</span>
                </a>
              </span>
              and
              <span>
                <a href="https://policies.google.com/terms?hl=en-US">
                  <span>Terms of Use</span>
                </a>
              </span>
              .
            </p>
          </div>
        </div>
        <div>
          <div>
            <h2>How We Use Your Information.</h2>
          </div>
          <div>
            <p>We may use information that we collect about you, including Personal Data, to:</p>
            <h2>Provide the Equitek Service.</h2>
            <p>
              We will use your information to provide our Website to you; including facilitating scheduling; managing
              your account; responding to your inquiries; and for other customer service and support purposes. We use
              the payment information you provide to us in order to alert you of past, current, and upcoming charges, to
              allow us to present the billing history to you on your billing page in the Website, and to perform
              internal financial processes, such as looking at the status of a credit card charge. In the event of a
              credit card dispute, we also share account information with your bank to verify the legitimacy of a
              charge.
            </p>
            <h2>Understand and improve our products.</h2>
            <p>
              We will perform research and analysis about your use of, or interest in, our products, services, or
              content, or products, services or content offered by others. We do this to help make our products better
              and to develop new products.
            </p>
            <h1>1.0 Communicate with you.</h1>
            <h2>1.1 Service related communications.</h2>
            <p>
              We may send you service and administrative emails to ensure the service is working properly. We will also
              email you regarding your calendar appointments. These messages are considered part of the service and you
              may not opt out of these messages.
            </p>
            <h2>1.2 Promotional.</h2>
            <p>
              Subject to your opt-out preference, we may send you emails about new product features or other news about
              Equitek or on topics we think would be relevant to you. You may opt out of receiving these communications
              at any time. Visit the ‘Your Rights and Choices’ section below. For Equitek Invitees, please be assured
              that we do not use the email addresses that you enter to schedule a meeting with a Equitek User to send
              any type of direct marketing.
            </p>
            <h2>1.3 Responding to your requests.</h2>
            <p>We will also use your information to respond to your questions or comments.</p>
            <h2>1.4 Administrative.</h2>
            <p>
              We may contact you to inform you about changes in your services, our service offering and other important
              service-related notices, such as changes to the Notice or about security or fraud notices.
            </p>
            <h2>Protecting Rights and Interests.</h2>
            <p>
              We will use your information to protect our rights and interests as well as the rights and interests of
              our Users and any other person, as well as to enforce this Notice or our Terms of Use.
            </p>
            <h2>Legal Compliance.</h2>
            <p>
              We may use your information to comply with applicable legal or regulatory obligations, including complying
              with requests from law enforcement or other governmental authorities, or in legal proceedings involving
              Equitek.
            </p>
            <h2>Other.</h2>
            <p>
              We also may use your information to manage our business or perform functions as otherwise described to you
              at the time of collection subject to your consent.
            </p>
          </div>
        </div>
        <div>
          <div>
            <h2>With Whom We May Share Your Information.</h2>
          </div>
          <div>
            <p>
              We do not sell your information to any third parties or disclose it in exchange for money or other
              valuable consideration. We do not share your Personal Data with others except as indicated within this
              Notice, or when we inform you and give you an opportunity to opt-out of having your Personal Data shared.
            </p>
            <p>
              We will never use Invitee data to send direct marketing via emails, SMS, physical mailings, or other
              similar communication channels to advertise or promote the use of our product and services or those of a
              third-party.
            </p>
            <p>We may share information we collect about you, including Personal Data, in the following ways:</p>
            <h2>With third-party Service Providers, agents, contractors, or government entities.</h2>
            <p>
              We use other companies, agents or contractors (“Service Providers”) to perform services on our behalf or
              to assist us with providing services to you:
            </p>
            <p>
              We may engage Service Providers to process credit card transactions or other payment methods. We may also
              engage Service Providers to provide services such as monitoring and developing Equitek services; aiding in
              communications, infrastructure, and IT services; customer service; debt collection; analyzing and
              enhancing data. These Service Providers may have access to your personal or other information in order to
              provide these functions. In addition, some of the above-listed types of information that we request may be
              collected by third-party Service Providers on our behalf.
            </p>
            <p>
              We may share information with Service Providers and government entities for legal, security, and safety
              purposes. This includes sharing information in order to enforce policies or contracts, address security
              breaches, and assist in the investigation of fraud, security issues, or other concerns.
            </p>
            <p>
              We require Service Providers to agree to take reasonable steps to keep the Personal Data that we provide
              to them secure. We do not authorize them to use or disclose your Personal Data except in connection with
              providing their services.
            </p>
            <h2>Affiliates.</h2>
            <p>
              We may disclose information to current or future affiliates or subsidiaries for research, marketing, and
              other purposes consistent with this Privacy Notice.
            </p>
            <p>
              We may share your data if we believe that disclosure is reasonably necessary to comply with a law,
              regulation, legal, or governmental request; to respond to a subpoena, court order, warrant, or other legal
              process; to enforce applicable Terms of Use or this Notice, including investigation of potential
              violations thereof; to protect the safety, rights, or property of the public, any person, or Equitek; to
              detect, prevent, or otherwise address, security or technical issues, illegal, or suspected illegal
              activities (including fraud); or as evidence in litigation in which we are involved, as part of a judicial
              or regulatory proceeding.
            </p>
            <p>
              If you are using Equitek as a paid member of an organization or using your organization’s email domain
              (thereby representing yourself as a member of the organization), we may share your email address and plan
              information with an authorized agent of your company upon request in order for them to administer the
              account for the company.
            </p>
            <p>
              <br />
              <span>
                <a href="https://privacy.microsoft.com/en-us/privacystatement">
                  <span>Microsoft&apos;s Privacy Statement</span>
                </a>
              </span>
            </p>
          </div>
        </div>
        <div>
          <div>
            <h2>Your Rights and Choices.</h2>
          </div>
          <div>
            <h2>Account.</h2>
            <p>
              In order to keep your Personal Data accurate and complete, you can log in to review and update your
              account information, including contact and billing information, via your account settings page. You may
              also contact us to request information about the personal data we have collected from you and to request
              the correction, modification or deletion of such Personal Data. We will do our best to honor your requests
              subject to any legal and contractual obligations. If you would like to make a request, cancel your account
              or request we delete or no longer use your account information to provide you our Website, contact us at
              <span>
                <a href="mailto:support@Equitek.com">
                  <span>support@Equitek.com</span>
                </a>
              </span>
              or the address set forth at the end of this Notice. Subject to applicable law, we will retain and use your
              account information only as necessary to comply with our legal obligations, resolve disputes, and enforce
              our agreements.
            </p>
            <h2>E-mail.</h2>
            <p>
              As described above, if you do not wish to receive promotional emails from us, you may opt out at any time
              by following the opt-out link contained in the email itself. Please note that it may take up to ten (10)
              days to process your request. Please also note that if you opt out of receiving marketing communications
              from us, we may continue to send to you service-related emails which are not available for opt-out. If you
              do not wish to receive any service-related emails from us, you have the option to delete your account.
            </p>
            <h2>Cookies.</h2>
            <p>
              You may also refrain from providing or may withdraw your consent for Cookies via your browser settings.
              Your browser’s help function should contain instructions on how to set your computer to accept all
              Cookies, to notify you when a Cookie is issued, or to not receive Cookies at any time. Please keep in mind
              that Cookies are required to authenticate Equitek Users as well as perform some actions within Equitek
              (such as to pay for an event as an Invitee via Equitek), so in order to use the Website, some Cookies are
              often required. You may also manage the use of targeting, performance, and functional cookies on this
              website by clicking the Cookie Settings link located on the footer of this page.
            </p>
            <h2>Third-party analytics services.</h2>
            <p>Some of the services used provide the ability to opt-out.</p>
            <ul>
              <li>
                <p>
                  You may opt out of Google Analytics’ services using the Opt-Out feature on their website. The Google
                  Analytics service is provided by Google Inc. You can opt-out from Google Analytics service from using
                  your information by installing the Google Analytics Opt-out Browser tool:
                  <span>
                    <a href="https://tools.google.com/dlpage/gaoptout">
                      <span>https://tools.google.com/dlpage/gaoptout</span>
                    </a>
                  </span>
                  . For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms
                  web page:
                  <span>
                    <a href="https://policies.google.com/privacy">
                      <span>https://policies.google.com/privacy</span>
                    </a>
                  </span>
                  .
                </p>
              </li>
              <li>
                <p>
                  You may opt out of Clearbit’s services using their opt-out feature:
                  <span>
                    <a href="https://claim.clearbit.com/claim">
                      <span>https://claim.clearbit.com/claim</span>
                    </a>
                  </span>
                  . For more information on the privacy practices of Clearbit, please visit their privacy policy:
                  <span>
                    <a href="https://clearbit.com/privacy">
                      <span>https://clearbit.com/privacy</span>
                    </a>
                  </span>
                  .
                </p>
              </li>
              <li>
                <p>
                  You may opt out of Facebook Pixel’s services using their opt-out feature:{" "}
                  <span>
                    <a href="https://facebook.com/help/568137493302217">
                      <span>https://facebook.com/help/568137493302217</span>
                    </a>
                  </span>
                  . For more information on the privacy practices of Facebook, please visit their Data Policy:
                  <span>
                    <a href="https://www.facebook.com/about/privacy">
                      <span>https://facebook.com/about/privacy</span>
                    </a>
                  </span>
                </p>
              </li>
            </ul>
            <h2>Additional Rights.</h2>
            <p>
              Subject to local law, you may have additional rights under the laws of your jurisdiction regarding your
              personal data, such as the right to complain to your local data protection authority.
            </p>
          </div>
        </div>
        <div>
          <div>
            <h2>Security and Storage of Information.</h2>
          </div>
          <div>
            <p>
              We have taken reasonable steps to help protect the personal information we collect. More information on
              Equitek security and storage practices is available
              <span>
                <a href="/security">
                  <span>here</span>
                </a>
              </span>
              .
            </p>
            <p>
              Equitek takes the security of your personal data very seriously. We work hard to protect the personal data
              that you provide from loss, misuse, and unauthorized access, or disclosure. Given the nature of
              communications and information processing technology, there is no guarantee that personal data will be
              absolutely safe from access, alteration, or destruction by a breach of any of our physical, technical, and
              managerial safeguards. Please visit our
              <span>
                <a href="/security">
                  <span>security and privacy page</span>
                </a>
              </span>
              for additional information about our safety standards.
            </p>
            <p>
              You should take steps to protect against unauthorized access to your device and account by, among other
              things, choosing a unique and complex password that nobody else knows or can easily guess and keeping your
              log-in and password private. We are not responsible for any lost, stolen, or compromised passwords or for
              any activity on your account via unauthorized password activity.
            </p>
            <p>
              We retain the personal data we collect for so long as is reasonably necessary to fulfill the purposes for
              which the data was collected, to perform our contractual and legal obligations, and for any applicable
              statute of limitations periods for the purposes of bringing and defending claims.
            </p>
          </div>
        </div>
        <div>
          <div>
            <h2>Third-Party Links.</h2>
          </div>
          <div>
            <p>
              Our Website may contain links to third-party websites and applications. Subject to your opt-out
              preferences (see Your Rights and Choices), we may also use third-party advertisers, ad networks, and other
              advertising, marketing, and promotional companies, to serve advertisements on our Website. Any access to
              and use of such linked websites and applications is not governed by this Notice but instead is governed by
              the privacy policies of those third parties. We do not endorse these parties, their content, or any
              products and services they offer, and we do not endorse these parties, their content, or any products and
              services they offer, and we are not responsible for the information practices of such third-party websites
              or applications.
            </p>
          </div>
        </div>
        <div>
          <div>
            <h2>Users Outside the USA.</h2>
          </div>
          <div>
            <p>Our application and database servers are located here in the United States.</p>
            <p>
              If you are an individual located in the European Economic Area, the United Kingdom, Canada or another
              jurisdiction outside of the United States with laws and regulations governing personal data collection,
              use, and disclosure that differ from United States laws, please be aware that information we collect
              (including through the use of methods such as Cookies and other web technologies) will be processed and
              stored in the United States or in other countries where we or our third-party Service Providers have
              operations. By submitting your personal data to Equitek and using Equitek, you expressly consent to have
              your personal data transferred to, processed, and stored in the United States or another jurisdiction
              which may not offer the same level of privacy protection as those in the country where you reside or are a
              citizen.
            </p>
            <p>
              In connection with the operation of its Website, Equitek may transfer your personal data to various
              locations, which may include locations both inside and outside of the European Economic Area. We process
              personal data submitted relating to individuals in Europe via the Standard Contractual Clauses.
            </p>
          </div>
        </div>
        <div>
          <div>
            <h2>Do Not Track.</h2>
          </div>
          <div>
            <p>
              We do not currently recognize or respond to browser-initiated Do Not Track signals as there is no
              consistent industry standard for compliance.
            </p>
          </div>
        </div>
        <div>
          <div>
            <h2>Dispute Resolution.</h2>
          </div>
          <div>
            <p>
              Details regarding Equitek’s dispute resolution process are available in the
              <span>
                <a href="/terms">
                  <span>Equitek Terms of Use</span>
                </a>
              </span>
              .
            </p>
          </div>
        </div>
        <div>
          <div>
            <h2>Changes to this Notice.</h2>
          </div>
          <div>
            <p>
              This Notice is current as of the Effective Date set forth above. This Notice may change if there is a
              material change to the way information is handled at Equitek, or to clarify our Notice or adjust clerical
              errors. If any changes are made, we’ll post them on this page, so please be sure to check back
              periodically. If you continue to use Equitek after those changes are in effect, you agree to the revised
              Notice.
            </p>
          </div>
        </div>
        <div>
          <div>
            <h2>Contacting Us.</h2>
          </div>
          <div>
            <p>
              If you have any questions or comments about this Notice, please contact us at
              <span>
                <a href="mailto:support@Equitek.com">
                  <span>support@Equitek.com</span>
                </a>
              </span>
              . For questions or comments outside of the Privacy Notice, please contact us at
              <span>
                <a href="mailto:support@Equitek.com">
                  <span>support@Equitek.com</span>
                </a>
              </span>
              .
            </p>
          </div>
        </div>
        <div>
          <div>
            <h2>Privacy Notice for California Residents.</h2>
          </div>
          <div>
            <p>
              <span>Effective January 1, 2021</span>
            </p>
            <p>
              This Privacy Notice for California Residents (“California Notice”) supplements the Equitek Privacy Notice.
              It applies solely to Equitek Users, Viewers, and Invitees who live in the State of California (“California
              Residents”). Equitek adopts this notice to comply with the California Consumer Privacy Act of 2019 and any
              subsequent amendments (“CCPA”). All terms used in this California Notice have the same meaning as when
              used in the CCPA. For frequently asked questions related to the CCPA please view our
              <span>
                <a href="/ccpa">
                  <span>CCPA FAQs Page</span>
                </a>
              </span>
              .
            </p>
          </div>
        </div>
        <div>
          <div>
            <h2>Information Equitek May Collect Regarding California Residents.</h2>
          </div>
          <div>
            <p>
              Equitek collects information that identifies, relates to, describes, references, is capable of being
              associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or
              device (“Personal Information”). In particular, our Website may have collected the following categories of
              Personal Information from California Resident Users, Invitees, and Viewers within the last twelve months,
              for the purpose of establishing, maintaining, and supporting the services that we provide through our
              Website:
            </p>
            <ul>
              <li>
                <p>A. Identifiers: real name; alias; email address; internet protocol address.</p>
              </li>
              <li>
                <p>
                  B. Personal Information categories listed in Cal. Civ. Code Sec. 1798.80(e): Real name; phone number.
                </p>
              </li>
              <li>
                <p>C. Commercial information: Purchase records regarding Equitek products.</p>
              </li>
              <li>
                <p>
                  D. Internet or other similar network activity: Data on Viewers’ interaction with the Equitek Website.
                </p>
              </li>
            </ul>
            <p>
              Equitek Obtains the Categories of Personal Information Listed Above from the Following Categories of
              Sources
            </p>
            <ul>
              <li>
                <p>Directly from our Users and Invitees.</p>
              </li>
              <li>
                <p>Indirectly from our Users and Invitees.</p>
              </li>
              <li>
                <p>Directly and indirectly from activity on our Website.</p>
              </li>
              <li>
                <p>From third parties that interact with us in connection with the services we perform.</p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            <h2>How California Resident Personal Information May Be Used.</h2>
          </div>
          <div>
            <p>
              We may use or disclose the Personal Information we collect for one or more of the following business
              purposes:
            </p>
            <ul>
              <li>
                <p>To fulfill or meet the reason for which the information is provided.</p>
              </li>
              <li>
                <p>To provide you with information or services that you request from us.</p>
              </li>
              <li>
                <p>
                  To provide you with email alerts and other notices concerning our services, or news that may be of
                  interest to you.
                </p>
              </li>
              <li>
                <p>
                  To carry out our obligations and enforce our rights arising from any contracts entered into between
                  you and us, including for billing and collections.
                </p>
              </li>
              <li>
                <p>To improve our Website.</p>
              </li>
              <li>
                <p>For testing, research, analysis and service development.</p>
              </li>
              <li>
                <p>As necessary or appropriate to protect Equitek and our Users.</p>
              </li>
              <li>
                <p>
                  To respond to law enforcement requests and as required by applicable law, court order, or governmental
                  regulations.
                </p>
              </li>
              <li>
                <p>
                  As described to you when your Personal Information was collected, or as otherwise set forth in the
                  CCPA.
                </p>
              </li>
              <li>
                <p>
                  To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other
                  sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy,
                  liquidation, or similar proceeding, in which Personal Information held by Equitek is among the assets
                  transferred.
                </p>
              </li>
              <li>
                <p>
                  Equitek will not collect additional categories of Personal Information or use the Personal Information
                  we collect for materially different, unrelated, or incompatible purposes without providing you notice.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            <h2>Sharing Personal Information.</h2>
          </div>
          <div>
            <p>
              We may disclose your Personal Information to a third-party for a business purpose. When we disclose
              Personal Information for a business purpose, we enter a contract that describes the purpose and requires
              the recipient to both keep that Personal Information confidential and not use it for any purpose except
              performing your contract.
            </p>
            <p>
              We disclose your Personal Information for a business purpose to the following categories of third parties:
            </p>
            <ul>
              <li>
                <p>Service Providers.</p>
              </li>
              <li>
                <p>
                  Third parties to whom you or your agents authorize us to disclose your Personal Information in
                  connection with the services we provide to you.
                </p>
              </li>
              <li>
                <p>Government representatives as required by law.</p>
              </li>
            </ul>
            <p>In the preceding twelve (12) months, we have not sold any Personal Information.</p>
          </div>
        </div>
        <div>
          <div>
            <h2>Access to Specific Information and Data Portability Rights.</h2>
          </div>
          <div>
            <p>
              You have the right to request that we disclose certain information to you about our collection and use of
              your Personal Information over the past 12 months. Once we receive and confirm your verifiable consumer
              request, we will disclose to you:
            </p>
            <ul>
              <li>
                <p>The categories of Personal Information we collected about you.</p>
              </li>
              <li>
                <p>The categories of sources for the Personal Information we collected about you.</p>
              </li>
              <li>
                <p>Our business or commercial purpose for collecting or selling that Personal Information.</p>
              </li>
              <li>
                <p>The categories of third parties with whom we share that Personal Information.</p>
              </li>
              <li>
                <p>The specific pieces of Personal Information that we have collected about you.</p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            <h2>Deletion Request Rights.</h2>
          </div>
          <div>
            <p>
              You have the right to request that we delete any of your Personal Information that we have collected from
              you and retained, subject to certain exceptions. All deletion requests will be managed in accordance with
              Equitek Deletion Procedures. Deletion requests should be sent to
              <span>
                <a href="mailto:support@Equitek.com">
                  <span>
                    <span>support@Equitek.com</span>
                  </span>
                </a>
              </span>
              . Once we receive and confirm your verifiable request, we will delete (and direct our Service Providers to
              delete) your Personal Information from our records, unless an exception applies.
            </p>
            <p>
              We may deny your deletion request if retaining the information is necessary for us or our service
              providers to:
            </p>
            <ul>
              <li>
                <p>
                  Complete the transaction for which we collected the Personal Information, provide a service that you
                  requested, take actions reasonably anticipated within the context of our ongoing business relationship
                  with you, or otherwise perform our contract with you.
                </p>
              </li>
              <li>
                <p>
                  Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or
                  prosecute those responsible for such activities.
                </p>
              </li>
              <li>
                <p>Debug software to identify and repair errors that impair existing intended functionality.</p>
              </li>
              <li>
                <p>Comply with the California Electronic Communications Privacy Act.</p>
              </li>
              <li>
                <p>
                  Enable solely internal uses that are reasonably aligned with consumer expectations based on your
                  relationship with us.
                </p>
              </li>
              <li>
                <p>Comply with a legal obligation.</p>
              </li>
              <li>
                <p>
                  Otherwise lawfully use that information in compatibility with the context in which you provided it.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            <h2>Exercising Access, Data Portability, and Deletion Rights.</h2>
          </div>
          <div>
            <p>
              To exercise the access, data portability, and deletion rights described above, please submit a verifiable
              consumer request to us by reaching out to
              <span>
                <a href="mailto:support@Equitek.com">
                  <span>support@Equitek.com</span>
                </a>
              </span>
              .
            </p>
            <p>
              Only you or a person registered with the California Secretary of State that you authorize to act on your
              behalf may make a verifiable consumer request related to your Personal Information. You may also make a
              verifiable consumer request on behalf of your minor child. You may only make a verifiable California
              Resident request for access or data portability twice within a 12-month period.
            </p>
            <p>The request must:</p>
            <ul>
              <li>
                <p>
                  Provide sufficiently detailed information to allow Equitek to reasonably verify that you are the
                  person to whom the requested Personal Information pertains or their authorized representative.
                </p>
              </li>
              <li>
                <p>Include sufficient detail to allow us to properly understand, evaluate, and respond to it.</p>
              </li>
              <li>
                <p>
                  We cannot respond to your request or provide you with Personal information if we cannot verify your
                  identity or authority to make the request and confirm the Personal Information relates to you.
                </p>
              </li>
              <li>
                <p>Making a verifiable consumer request does not require you to create an account with us.</p>
              </li>
              <li>
                <p>
                  Equitek will only use Personal Information provided in a verifiable consumer request to verify the
                  requestor&apos;s identity or authority to make the request.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            <h2>Response Timing and Format.</h2>
          </div>
          <div>
            <p>
              We will attempt to respond to California Resident requests in as timely a fashion as possible. In the
              event of delays over 60 days, we will inform you of the reason and extension period in writing. If you
              have an account with us, we will deliver our written response to that account. Any disclosures we provide
              will only cover the 12-month period preceding the verifiable receipt of a California Resident’s request.
              The response we provide will explain the reasons we cannot comply with a request, if applicable.
            </p>
            <p>
              We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive,
              repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why
              we made that decision and provide you with a cost estimate before fulfilling your request.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export const TermsAndCondition = (): JSX.Element => {
  return (
    <div>
      <NavBar className="standard-container app bg-transparent px-4" />
      <Section1 />
      <Footer />
    </div>
  );
};
