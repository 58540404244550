// React Section component
import React from "react";
import facilities from "../../assets/facilities.jpg";
import professionals from "../../assets/professionals.jpg";
import bluecheck from "../../assets/blue-check.svg";
import doctor1 from "../../assets/home-doctor1.jpg";
import doctor2 from "../../assets/home-doctor2.jpg";

const data: Record<string, any> = {
  professionals: {
    Reduce: ["Burnout", "Medical mistakes", "Disengagement"],
    Improve: ["Self scheduling", "Earning/Pay", "Pre-screening speed", "Work-life balance"],
    Create: [
      "Real-time open shifts and assignment marketplace",
      "Real-time open direct communication channel with facilities and other professionals",
    ],
  },
  facilities: {
    Reduce: ["Premium pay cost", "Open shifts", "Turnover"],
    Improve: ["Shift management", "Hiring speed", "Staff satisfaction", "Patient care"],
    Create: [
      "Real-time open shifts broadcasting marketplace",
      "Effective & customizable scheduling",
      "Powerful Data insights & monitoring ",
      "Collaborative platform for facilities",
    ],
  },
};

export const Section2 = (): JSX.Element => {
  const [selected, setSelected] = React.useState(0);

  return (
    <div className="standard-container app mt-16">
      <h1 className="font-poppins text-4xl font-bold text-outerspace">
        Significantly more cost effective and innovative solutions
      </h1>
      <h2 className="mt-4 font-poppins text-aurometalsaurus">Eliminates Middleman and Staffing agencies</h2>

      <div className={`mt-8 flex`}>
        <button
          className={`mr-4 cursor-pointer rounded-full py-2 px-6 font-poppins text-xl font-medium ${
            selected === 0
              ? "bg-ultramarinblue text-white"
              : "border border-solid border-ultramarinblue leading-[30px] text-ultramarinblue"
          }`}
          onClick={() => {
            setSelected(selected === 1 ? 0 : 1);
          }}
        >
          Facilities
        </button>
        <button
          className={`mr-4 cursor-pointer rounded-full py-2 px-4 font-poppins text-xl font-medium ${
            selected === 1
              ? "bg-ultramarinblue text-white"
              : "border border-solid border-ultramarinblue leading-[30px] text-ultramarinblue"
          }`}
          onClick={() => {
            setSelected(selected === 1 ? 0 : 1);
          }}
        >
          Professionals
        </button>
      </div>

      <div className="mt-8 flex flex-col md:flex-row">
        <div className="relative flex-[1_1_50%]">
          <img src={selected === 0 ? facilities : professionals} alt="" className="rounded-3xl" />
          {/*  transparent card layout */}
          {selected === 0 && (
            <></>
            // <div className="absolute bottom-[-40%] right-[-5%] m-2 flex min-w-[120px] flex-col rounded-2xl bg-white/[.75] p-3 shadow-lg sm:right-[10%] sm:bottom-[-30%] md:bottom-[-15%] md:right-[-10%] md:mt-8 md:min-w-[240px] md:p-6 lg:min-w-[300px]">
            //   <h2 className="font-poppins text-xl font-semibold">Available Doctors</h2>
            //   <h4 className="pt-2 font-poppins text-sm font-normal leading-[24px] text-darkliver md:pt-4">
            //     Select Doctors
            //   </h4>

            //   <div className="mt-2 flex md:mt-6">
            //     <div className="flex flex-col">
            //       {/* circular image */}
            //       <div className="relative h-12 w-12">
            //         <img src={doctor1} alt="" className="h-full w-full rounded-full" />
            //         <div className="absolute bottom-0 right-0 h-4 w-4 rounded-full bg-green-600" />
            //       </div>
            //     </div>
            //     <div className="flex flex-col pl-4">
            //       <h4 className="font-poppins font-semibold">Drain Smith, MD</h4>
            //       <h4 className="font-poppins text-sm font-normal leading-[24px] text-darkliver">Vascular Surgery</h4>
            //     </div>
            //   </div>
            //   <div className="mt-2 flex">
            //     <div className="flex flex-col">
            //       <div className="relative h-12 w-12">
            //         <img src={doctor2} alt="" className="h-full w-full rounded-full" />
            //         <div className="absolute bottom-0 right-0 h-4 w-4 rounded-full bg-green-600" />
            //       </div>
            //     </div>
            //     <div className="flex flex-col pl-4">
            //       <h4 className="font-poppins font-semibold">Janefa Lopez, CRNP</h4>
            //       <h4 className="font-poppins text-sm font-normal leading-[24px] text-darkliver">Family Practice</h4>
            //     </div>
            //   </div>

            //   {/*  Button */}
            //   <button className="mt-8 cursor-pointer rounded-full bg-ultramarinblue py-2 px-6 font-poppins text-xl font-medium text-white">
            //     Find Doctors
            //   </button>
            // </div>
          )}
        </div>

        <div
          className={`flex min-h-[600px] flex-[1_1_50%] flex-col items-center justify-around px-2 md:mt-0 md:pl-16 ${
            selected === 0 ? "mt-48" : "mt-6"
          } md:mt-0`}
        >
          {Object.keys(data[selected === 0 ? "facilities" : "professionals"]).map((key: string, index) => (
            <div
              className="relative mt-16 min-w-full rounded-xl border border-[#EAF0FF] px-8 shadow-lg md:mt-0"
              key={key}
            >
              <div className="-mt-8 -ml-4 flex items-center">
                <img src={bluecheck} alt="" />
                <h1
                  className="ml-3 font-poppins text-3xl font-medium"
                  style={{
                    background: "linear-gradient(90deg, #009FFF 0%, #0079FF 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {key}
                </h1>
              </div>
              <ul className="ml-4 mt-4 mb-4 list-disc">
                {data[selected === 0 ? "facilities" : "professionals"][key].map((item: string, _index: number) => (
                  <li key={item} className="font-poppins font-normal leading-[24px] text-darkliver">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
