// React Section component
import React from "react";
import techIcon from "../../assets/tech-icon.svg";
import botIcon from "../../assets/bot-icon.svg";
import healthIcon from "../../assets/health-icon.svg";
import { useNavigate } from "react-router-dom";
import { paths } from "../../constants";

export const Section3 = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div className="standard-container app mt-16">
      <h1 className="font-poppins text-4xl font-bold text-outerspace">
        Comprehensive technology enabling{" "}
        <b
          className="cursor-pointer text-ultramarinblue"
          onClick={() => {
            navigate(paths.myshift);
          }}
        >
          MyShiftHealth
        </b>{" "}
        applications
      </h1>

      <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-3">
        <div className="hover:card-hover col-span-1 flex flex-col items-start p-4 hover:bg-ultramarinblue">
          <img src={techIcon} alt="" width="25%" />
          <h1 className="mt-4 font-poppins text-xl font-semibold">Tech-enabled Talent Acquisition</h1>
          <p className="mb-8 mt-4 text-aurometalsaurus">
            Automated efficient, timely, and simple process to attract, source, recruit, and onboard employees and
            contractors; stretching beyond local market boundaries.
          </p>
          <button
            onClick={() => {
              navigate(paths.myshift);
            }}
            className="mt-auto text-tuftsblue"
          >
            Learn more
          </button>
        </div>
        <div className="hover:card-hover col-span-1 flex flex-col items-start p-4 hover:bg-ultramarinblue">
          <img src={botIcon} alt="" width="25%" />
          <h1 className="mt-4 font-poppins text-xl font-semibold">AI-enabled Decision Support</h1>
          <p className="mb-8 mt-4 text-aurometalsaurus ">
            Our proprietary software is configured with artificial intelligence and predictive analytics to provide
            relevant, timely, and actionable information to assign the right clinician, in the right setting, at the
            right time.
          </p>
          <button
            onClick={() => {
              navigate(paths.analytics);
            }}
            className="mt-auto text-tuftsblue"
          >
            Learn more
          </button>
        </div>
        <div className="hover:card-hover col-span-1 flex flex-col items-start p-4 hover:bg-ultramarinblue">
          <img src={healthIcon} alt="" width="25%" />
          <h1 className="mt-4 font-poppins text-xl font-semibold">Workforce Management</h1>
          <p className="mb-8 mt-4 text-aurometalsaurus ">
            We provide fully-integrated mobile and web applications to streamline frontline operations to optimize
            staffing and scheduling needs.
          </p>
          <button
            onClick={() => {
              navigate(paths.workforceManagement);
            }}
            className="mt-auto text-tuftsblue"
          >
            Learn more
          </button>
        </div>
      </div>
    </div>
  );
};
