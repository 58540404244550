import React from "react";
import vector2 from "../../assets/vector-2.svg";
import costDilemma from "../../assets/cost-dilemma.svg";
import marketFocus from "../../assets/market-forces.svg";
import qualityOfCare from "../../assets/quality-of-care.svg";

export const Section1 = (): JSX.Element => {
  return (
    <div
      style={{ background: "linear-gradient(180deg, #FFFFFF 0%, #FAF9FF 100%)" }}
      className="standard-container app"
      id="why-equitek"
    >
      <div className="mt-16 flex flex-col items-center">
        <h1
          className="bg-clip-text text-center font-poppins text-6xl font-black text-darkliver"
          style={{
            background: "linear-gradient(90deg, #009FFF 0%, #0079FF 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Why Equitek?
        </h1>
        <img src={vector2} alt="" width="260px" className="mt-2" />
        <h1 className="mt-12 text-center font-poppins text-4xl font-bold text-outerspace sm:text-left">
          A patient-first approach to solving staffing challenges
        </h1>
      </div>

      <div className="mt-16 grid grid-cols-1 gap-16 sm:grid-cols-3 sm:gap-4 md:gap-24">
        <div className="flex flex-col items-center p-4 text-justify">
          <img src={qualityOfCare} alt="" />
          <h1 className="mt-8 font-poppins text-xl font-semibold text-outerspace">Quality of care</h1>
          <h2 className="mt-8 text-center font-poppins text-aurometalsaurus">
            Ensure quality and availability of care by delivering appropriate staffing levels, and direct access to
            resources to advance organizational mission and values.
          </h2>
        </div>
        <div className="flex flex-col items-center p-4 text-justify">
          <img src={costDilemma} alt="" />
          <h1 className="mt-8 font-poppins text-xl font-semibold text-outerspace">Cost vs Speed Dilemma</h1>
          <h2 className="mt-8 text-center font-poppins text-aurometalsaurus">
            Eliminate factors and platforms that inhibit speed. Protect key revenue streams while controling workforce
            planning processes and resources.
          </h2>
        </div>
        <div className="flex flex-col items-center p-4 text-justify">
          <img src={marketFocus} alt="" />
          <h1 className="mt-8 font-poppins text-xl font-semibold text-outerspace">Market Forces</h1>
          <h2 className="mt-8 text-center font-poppins text-aurometalsaurus">
            Optimize reach to resources, better control and predictability of spend; in simplistic, efficient, and
            visible way.
          </h2>
        </div>
      </div>
    </div>
  );
};
