import React from "react";
import Header from "./Header";
import backgroundTopCurve from "../../assets/common/background-top-curve.svg";
import { Footer } from "../Common/Footer";
import { Section1 } from "./Section1";
import { Section2 } from "./Section2";
import { Section3 } from "./Section3";
import { Section4 } from "./Section4";
import { Section5 } from "./Section5";

export const Home = (): JSX.Element => {
  return (
    <div className="h-screen overflow-y-scroll">
      <Header />
      <div
        className="-mt-2 h-[10%] w-[100%]"
        style={{
          backgroundImage: `url(${backgroundTopCurve})`,
          backgroundColor: "white",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <div
        className="-mb-2 h-[90px] w-[100%] sm:h-[120px] sm:w-[100%] md:h-[270px] lg:h-[360px]"
        style={{
          backgroundImage: `url(${backgroundTopCurve})`,
          backgroundColor: "white",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          transform: "rotate(3.142rad)",
        }}
      />
      <Footer />
    </div>
  );
};
