import React from "react";
import { NavBar } from "../Common/NavBar";
import privacyPolicy from "../../assets/privacy-policy.svg";
import "./PrivacyPolicy.css";
import { Footer } from "../Common/Footer";
import { Link } from "react-router-dom";

const Section1 = (): JSX.Element => {
  return (
    <div className="standard-container app">
      <div className="grid grid-cols-2 items-center">
        <div className="col-span-1">
          <h1 className="text-5xl font-semibold">Privacy Policy Notice</h1>
        </div>
        <div className="col-span-1">
          <img src={privacyPolicy} alt="privacy policy" className="scale-75" />
        </div>
      </div>

      <div id="privacy-table" className="mt-16">
        <div>
          <div className="col-span-3 mt-16 ">
            <h1 className="font-poppins text-[22px] font-semibold leading-[30px]">Introduction</h1>
          </div>
          <div>
            <p className="ml-2 mt-8 font-poppins text-[20px] font-light leading-[30px] text-darkliver">
              Equitek, LLC (“Company” or “We”) respect your privacy and are committed to protecting it through our
              compliance with this policy.
            </p>
            <p className="ml-2 mt-4 font-poppins text-[20px] font-light leading-[30px] text-darkliver">
              This policy describes the types of information we may collect from you or that you may provide when you
              visit the website www.equitek.net (our “Website”) or download, install, register with, access or use the
              mobile app at www.MyShiftHealth.com (the “App”) and use our services on the Website or App (collectively,
              the “Services”) and our practices for collecting, using, maintaining, protecting, and disclosing that
              information.
            </p>
            <p className="ml-2 mt-4 font-poppins text-[20px] font-light leading-[30px]">
              This policy applies to information we collect:
              <ul className="ml-12 mt-1 text-left">
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Workforce management and financial metrics
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Operational and analytical dashboards
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Supply (Facility) and demand (professional) side views
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  On this Website or through the Services.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  In email, text, and other electronic communication between you and this Website, the App or otherwise
                  through the Services.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  When you interact with applications on third-party websites and services, if those applications
                  include links to this policy.
                </li>
              </ul>
            </p>
            <p className="ml-2 mt-0 py-2 font-poppins text-[20px] font-light leading-[28px]">
              It does not apply to information collected by:
              <ul className="ml-12 mt-1 text-left">
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Us offline or through any other means; or
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Any third party, including through any application or content (including advertising) that may link to
                  or be accessible from or through the Website.
                </li>
              </ul>
            </p>
            <p className="ml-2 mt-4 font-poppins text-[20px] font-light leading-[36px] text-darkliver">
              Please read this policy carefully to understand our policies and practices regarding your information and
              how we will treat it. If you do not agree with our policies and practices, your choice is not to use our
              Services. By downloading, registering with, accessing or using any of the Services, you agree to this
              privacy policy. This policy may change from time to time. Your continued use of this Website after we make
              changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.
            </p>
          </div>
        </div>
        <div>
          <div className="col-span-3 mt-16 ">
            <h1 className="font-poppins text-[22px] font-semibold leading-[30px]">Children Under the Age of 18</h1>
          </div>
          <div>
            <p className="ml-2 mt-8 font-poppins text-[20px] font-light leading-[30px] text-darkliver">
              Our Service is not intended for children under 18 years of age. No one under age 18 may provide any
              personal information to or on the Website or through the Services. We do not knowingly collect personal
              information from children under 16. If you are under 18, do not use or provide any information on this
              Website or through any of its features or otherwise, register on the Website, use any of the interactive
              features of this Website, or provide any information about yourself to us, including your name, address,
              telephone number, email address, or any screen name or user name you may use. If we learn we have
              collected or received personal information from a child under 18 without verification of parental consent,
              we will delete that information. If you believe we might have any information from or about a child under
              18, please contact us at info@equitek.net or info@MyShiftHealth.com.
            </p>
          </div>
        </div>
        <div>
          <div className="col-span-3 mt-16 ">
            <h1 className="font-poppins text-[22px] font-semibold leading-[30px]">
              Information Outside of the United States{" "}
            </h1>
          </div>
          <div>
            <p className="ml-2 mt-8 font-poppins text-[20px] font-light leading-[30px] text-darkliver">
              The Website may be stored and processed in the United States and is intended only for users located in the
              United States. If you are located in the European Union or elsewhere outside of the United States, please
              do not use the Website and our Services or provide any information to us or otherwise through the Website.
              Be aware that information we collect will be transferred to and processed in the United States. By using
              the Website or providing us with any information, you consent to this transfer and processing of
              information in the United States.
            </p>
          </div>
        </div>
        <div>
          <div className="col-span-3 mt-16 ">
            <h1 className="font-poppins text-[22px] font-semibold leading-[30px]">
              Information We Collect About You and How We Collect It
            </h1>
          </div>
          <div>
            <p className="ml-2 mt-8 font-poppins text-[20px] font-light leading-[30px] text-darkliver">
              We collect several types of information from and about users of our Services, including information:
            </p>
            <p>
              <ul className="ml-12 mt-1 text-left">
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  By which you may be personally identified, such as name, postal address, email address, telephone
                  number, or professional licensure, certain preferences and background check information any other
                  identifier by which you may be contacted online or offline (“personal information”).
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  That is about you but individually does not identify you; and/or
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  About your internet connection, the equipment you use to access our Website, and usage details.
                </li>
              </ul>
            </p>
            <p className="ml-2 mt-2 font-poppins text-[20px] font-light leading-[30px] text-darkliver">
              We collect this information:
            </p>
            <p>
              <ul className="ml-12 mt-1 text-left">
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Directly from you when you provide it to us.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Automatically as you navigate through the Website. Information collected automatically may include
                  usage details, IP addresses, and information collected through cookies, web beacons, and other
                  tracking technologies.
                </li>

                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  From third parties, for example, for background check purposes or employment history.
                </li>
              </ul>
            </p>
            <h1 className="ml-2 font-poppins text-[18px] font-semibold leading-[30px]">
              <i>Information You Provide to Us</i>
            </h1>
            <p className="ml-2 mt-8 font-poppins text-[20px] font-light leading-[30px] text-darkliver">
              The information we collect on or through our services may include:
            </p>
            <p>
              <ul className="ml-12 mt-1 text-left">
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Information that you provide by filling in forms on our Service. This includes information provided at
                  the time of registering to use our Website or App, subscribing to our service, or requesting further
                  services. We may also ask you for information when you report a problem with our Website.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Records and copies of your correspondence (including email addresses), if you contact us.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Your responses to surveys that we might ask you to complete for our business purposes.
                </li>

                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Details of transactions you carry out through our Website or App.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Your search queries on the Website or in the App.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  [ANY OTHER TYPES OF INFORMATION THE USER PROVIDES.]
                </li>
              </ul>
            </p>

            <p className="ml-2 mt-5 font-poppins text-[20px] font-light leading-[30px]">
              You also may provide information to be published or displayed (hereinafter, “posted” )on public areas of
              the Website, or transmitted to other users of the Website or App or third parties (collectively, “User
              Contributions”). Your User Contributions are posted on and transmitted to others at your own risk. Please
              be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the
              actions of other users of the Service with whom you may choose to share your User Contributions.
              Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized
              persons.
            </p>
            <h1 className="ml-2 font-poppins text-[18px] font-semibold leading-[30px]">
              <i>Information We Collect Through Automatic Data Collection Technologies </i>
            </h1>
            <p className="ml-2 font-poppins text-[20px] font-light leading-[30px]">
              As you navigate through and interact with our Website or when you download, access, and use the App, we
              may use automatic data collection technologies to collect certain information about your equipment,
              browsing actions, and patterns, including:
            </p>
            <p>
              <ul className="ml-12 mt-1 text-left">
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Details of your visits to our Website or App, including traffic data, location data, logs, and other
                  communication data and the resources that you access and use on the Website.
                </li>

                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Information about your computer and internet connection, including the device&apos;s unique device
                  identifier, your IP address, operating system, and browser type, mobile network information, and the
                  device&apos;s telephone number.
                </li>
              </ul>
            </p>
            <p className="ml-2 py-2 font-poppins text-[20px] font-light leading-[30px]">
              The information we collect automatically may include personal information, or we may maintain it or
              associate it with personal information we collect in other ways or receive from third parties. It helps us
              to improve our Website and App and to deliver a better and more personalized service, including by
              enabling us to:
            </p>
            <p>
              <ul className="ml-12 text-left">
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Estimate our audience size and usage patterns.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Store information about your preferences, allowing us to customize our Website or App according to
                  your individual interests.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Speed up your searches.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Recognize you when you return to our Website.
                </li>
              </ul>
            </p>
            <p className="ml-2 py-2 font-poppins text-[20px] font-light leading-[30px]">
              The technologies we use for this automatic data collection may include:
            </p>
            <p>
              <ul className="ml-12 mt-1 text-left">
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  <b className="font-bold">Cookies (or browser/mobile cookies).</b> A cookie is a small file placed on
                  the hard drive of your computer o smartphone.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  <b className="font-bold">Web Beacons.</b> Pages of our Website and our emails may contain small
                  electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel
                  gifs) that permit the Company, for example, to count users who have visited those pages or opened an
                  email and for other related website statistics (for example, recording the popularity of certain
                  website content and verifying system and server integrity).
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  <b className="font-bold">Flash Cookies.</b> Certain features of our Website or the App may use local
                  stored objects (or Flash cookies) to collect and store information about your preferences and
                  navigation to, from, and on our Website. Flash cookies are not managed by the same browser settings as
                  are used for browser/mobile cookies.
                </li>
              </ul>
            </p>
            <p className="ml-2 py-1 font-poppins text-[20px] font-light leading-[30px]">
              We do not collect personal information automatically, but we may tie this information to personal
              information about you that we collect from other sources or you provide to us.
            </p>
          </div>
        </div>
        <div>
          <div className="col-span-3 mt-16 ">
            <h1 className="font-poppins text-[22px] font-semibold leading-[30px]">
              Third-Party Use of Cookies and Other Tracking Technologies
            </h1>
          </div>
          <div>
            <p className="ml-2 mt-8 font-poppins text-[20px] font-light leading-[30px]">
              Some content or applications, including advertisements, on the Website/App are served by third-parties,
              including advertisers, ad networks and servers, content providers, and application providers. These third
              parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect
              information about you when you use our website or the App. The information they collect may be associated
              with your personal information or they may collect information, including personal information, about your
              online activities over time and across different websites and other online services. They may use this
              information to provide you with interest-based (behavioral) advertising or other targeted content.
            </p>
            <p className="ml-2 py-1 font-poppins text-[20px] font-light leading-[30px]">
              We do not control these third parties tracking technologies or how they may be used. If you have any
              questions about an advertisement or other targeted content, you should contact the responsible provider
              directly.
            </p>
          </div>
        </div>
        <div>
          <div className="col-span-3 mt-16 ">
            <h1 className="font-poppins text-[22px] font-semibold leading-[30px]">How We Use Your Information</h1>
          </div>
          <div>
            <p className="ml-2 mt-8 font-poppins text-[20px] font-light leading-[30px]">
              We use information that we collect about you or that you provide to us, including any personal
              information:
            </p>
            <p>
              <ul className="ml-12 mt-1 text-left">
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  To present our Website and App and its contents to you.
                </li>

                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  To provide you with information, products, or services that you request from us.
                </li>

                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  To fulfill any other purpose for which you provide it.
                </li>

                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  To provide you with notices about your account/subscription.
                </li>

                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  To carry out our obligations and enforce our rights arising from any contracts entered into between
                  you and us, including for billing and collection.
                </li>

                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  To notify you about changes to our Website or any products or services we offer or provide though it.
                </li>

                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  To allow you to participate in interactive features on our Website.
                </li>

                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  In any other way we may describe when you provide the information.
                </li>

                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  For any other purpose with your consent.
                </li>
              </ul>
            </p>
            <p className="ml-2 py-1 font-poppins text-[20px] font-light leading-[30px]">
              We may also use your information to contact you about our own and third-parties&apos; goods and services
              that may be of interest to you, and general information related to our community. If you do not want us to
              use your information in this way, please contact us at info@equitek.net or info@MyShiftHealth.com..
            </p>
          </div>
        </div>
        <div>
          <div className="col-span-3 mt-16 ">
            <h1 className="font-poppins text-[22px] font-semibold leading-[30px]">Disclosure of Your Information</h1>
          </div>
          <div>
            <p className="ml-2 mt-8 font-poppins text-[20px] font-light leading-[30px]">
              We may disclose aggregated information about our users, and information that does not identify any
              individual or device, without restriction.
            </p>
            <p className="ml-2 py-2 font-poppins text-[20px] font-light leading-[30px]">
              We may disclose personal information that we collect or you provide as described in this privacy policy:
            </p>
            <p>
              <ul className="ml-12 mt-1 text-left">
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  To our subsidiaries and affiliates.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  To contractors, service providers, and other third parties we use to support our business and who are
                  bound by contractual obligations to keep personal information confidential and use it only for the
                  purposes for which we disclose it to them.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
                  dissolution, or other sale or transfer of some or all of Equitek, LLC&apos;s assets, whether as a
                  going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal
                  information held by Equitek, LLC about our Website or App users is among the assets transferred.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  To third parties to market their products or services to you if you have not opted out of these
                  disclosures. We contractually require these third parties to keep personal information confidential
                  and use it only for the purposes for which we disclose it to them.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Website activity such as percentage of assignments completed successfully, number of shift swaps or
                  call-offs and similar information will be shared with employers/facilities.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  To fulfill the purpose for which you provide it.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  For any other purpose disclosed by us when you provide the information.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  With your consent.
                </li>
              </ul>
            </p>
            <p className="ml-2 py-2 font-poppins text-[20px] font-light leading-[30px]">
              We may also disclose your personal information:
            </p>
            <p>
              <ul className="ml-12 mt-1 text-left">
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  To comply with any court order, law, or legal process, including to respond to any government or
                  regulatory request.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  To enforce or apply our{" "}
                  <Link to="https://d2l2e968qjru3z.cloudfront.net/terms-and-conditions" style={{ color: "#00F" }}>
                    {" "}
                    terms of use{" "}
                  </Link>{" "}
                  or our rights arising from any contracts entered into between you and us, including the App{" "}
                  <Link to="https://d2l2e968qjru3z.cloudfront.net/eula" style={{ color: "#00F" }}>
                    {" "}
                    end user license agreement{" "}
                  </Link>{" "}
                  and other agreements, including for billing and collection purposes.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of
                  Equitek, LLC, our customers, or others. This includes exchanging information with other companies and
                  organizations for the purposes of fraud protection and credit risk reduction.
                </li>
              </ul>
            </p>
          </div>
        </div>
        <div>
          <div className="col-span-3 mt-16 ">
            <h1 className="font-poppins text-[22px] font-semibold leading-[30px]">
              Choices About How We Use and Disclose Your Information
            </h1>
          </div>
          <div>
            <p className="ml-2 mt-8 font-poppins text-[20px] font-light leading-[30px]">
              We strive to provide you with choices regarding the personal information you provide to us. We have
              created mechanisms to provide you with the following control over your information:
            </p>
            <p>
              <ul className="ml-12 mt-1 text-left">
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  <b className="font-bold">Tracking Technologies and Advertising.</b> You can set your browser to refuse
                  all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse
                  cookies, please note that some parts of the Service may then be inaccessible or not function properly.
                </li>

                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  <b className="font-bold">Location Information.</b> You can choose whether or not to allow the App to
                  collect and use real-time information about your device&apos;s location through the device&apos;s
                  privacy settings. If you block the use of location information, some parts of the App may become
                  inaccessible or not function properly.
                </li>

                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  <b className="font-bold">Promotion by the Company.</b> If you do not wish to have your [email
                  address/contact information] used by the Company to promote our own or third parties&apos; products or
                  services, you can opt-out by sending us an email stating your request to info@equitek.net or
                  info@MyShiftHealth.com. If we have sent you a promotional email, you may send us a return email asking
                  to be omitted from future email distributions.
                </li>
              </ul>
            </p>
            <p className="ml-2 py-2 font-poppins text-[20px] font-light leading-[30px]">
              We do not control third parties&apos; collection or use of your information to serve interest-based
              advertising. However these third parties may provide you with ways to choose not to have your information
              collected or used in this way. You can opt out of receiving targeted ads from members of the Network
              Advertising Initiative (&quot;NAI&quot;) on the NAI&apos;s website.
            </p>
          </div>
        </div>
        <div>
          <div className="col-span-3 mt-16 ">
            <h1 className="font-poppins text-[22px] font-semibold leading-[30px]">
              Accessing and Correcting Your Information
            </h1>
          </div>
          <div>
            <p className="ml-2 mt-8 font-poppins text-[20px] font-light leading-[30px]">
              You can review and change certain your personal information by logging into the Website or App and
              visiting your account profile page or by sending us an email at info@equitek.net or info@MyShiftHealth.com
              to request access to, correct or delete any personal information that you have provided to us. We cannot
              delete your personal information except by also deleting your user account. We may not accommodate a
              request to change information if we believe the change would violate any law or legal requirement or cause
              the information to be incorrect.
            </p>
            <p className="ml-2 font-poppins text-[20px] font-light leading-[30px]">
              If you delete your User Contributions from the Website or App, copies of your User Contributions may
              remain viewable in cached and archived pages, or might have been copied or stored by other Website or App
              users. Proper access and use of information provided on the Website or App, including User Contributions,
              is governed by our{" "}
              <Link to="https://d2l2e968qjru3z.cloudfront.net/terms-and-conditions" style={{ color: "#00F" }}>
                {" "}
                terms of use{" "}
              </Link>
              .
            </p>
          </div>
        </div>
        <div>
          <div className="col-span-3 mt-16 ">
            <h1 className="font-poppins text-[22px] font-semibold leading-[30px]">Your State Privacy Rights</h1>
          </div>
          <div>
            <p className="ml-2 mt-8 font-poppins text-[20px] font-light leading-[30px]">
              Certain state consumer privacy laws may provide their residents with additional rights regarding our use
              of their personal information, including the rights to:
            </p>
            <p>
              <ul className="ml-12 mt-1 text-left">
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Confirm whether we process their personal information.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Access and delete certain personal information.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Data portability.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Opt-out of personal data processing for targeted advertising and sales.
                </li>
              </ul>
            </p>
            <p className="ml-2 py-2 font-poppins text-[20px] font-light leading-[30px]">
              Colorado, Connecticut, and Virginia also provide their state residents with rights to:
            </p>
            <p>
              <ul className="ml-12 text-left">
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Correct inaccuracies in their personal information, taking into account the information&apos;s nature
                  processing purpose.
                </li>
                <li className="list-disc font-poppins text-[18px] font-light leading-[30px] text-darkliver">
                  Opt-out of profiling in furtherance of decisions that produce legal or similarly significant effects.
                </li>
              </ul>
            </p>
            <p className="ml-2 py-2 font-poppins text-[20px] font-light leading-[30px]">
              To exercise any of these rights please contact us at info@equitek.net or info@MyShiftHealth.com.
            </p>
          </div>
        </div>
        <div>
          <div className="col-span-3 mt-16 ">
            <h1 className="font-poppins text-[22px] font-semibold leading-[30px]">Data Security</h1>
          </div>
          <div>
            <p className="ml-2 mt-8 font-poppins text-[20px] font-light leading-[30px]">
              The safety and security of your information also depends on you. Where we have given you (or where you
              have chosen) a password for access to certain parts of our Service, you are responsible for keeping this
              password confidential. We ask you not to share your password with anyone. The information you share in
              public areas may be viewed by any user of the Website or App.
            </p>
            <p className="ml-2 py-2 font-poppins text-[20px] font-light leading-[30px]">
              Unfortunately, the transmission of information via the internet is not completely secure. Although we do
              our best to protect your personal information, we cannot guarantee the security of your personal
              information transmitted through the Services. Any transmission of personal information is at your own
              risk. We are not responsible for circumvention of any privacy settings or security measures contained on
              the Website or in the App.
            </p>
          </div>
        </div>
        <div>
          <div className="col-span-3 mt-16 ">
            <h1 className="font-poppins text-[22px] font-semibold leading-[30px]">Changes to Our Privacy Policy</h1>
          </div>
          <div>
            <p className="ml-2 mt-8 font-poppins text-[20px] font-light leading-[30px]">
              We may update our privacy policy from time to time. If we make material changes to how we treat our
              users&apos; personal information, we will notify you through a notice on the Website home page. The date
              the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring
              we have an up-to-date active and deliverable email address for you, and for periodically visiting our
              Website or App and this privacy policy to check for any changes.
            </p>
          </div>
        </div>
        <div>
          <div className="col-span-3 mt-16 ">
            <h1 className="font-poppins text-[22px] font-semibold leading-[30px]">Contact Information</h1>
          </div>
          <div>
            <p className="ml-2 mt-8 font-poppins text-[20px] font-light leading-[30px]">
              To ask questions or comment about this privacy policy and our privacy practices, contact us at:
              info@equitek.net or info@MyShiftHealth.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export const PrivacyPolicy = (): JSX.Element => {
  return (
    <div>
      <NavBar className="standard-container app bg-transparent px-4" />
      <Section1 />
      <Footer />
    </div>
  );
};
