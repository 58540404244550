// React Section component
import React from "react";
import { Link } from "react-router-dom";
import { paths } from "../../constants";

export const Section5 = (): JSX.Element => {
  return (
    <div className="app standard-container mt-16">
      <div className="rounded bg-aliceblue px-4 pt-8 pb-8 text-center shadow-lg shadow-[#bcbbe854] sm:pt-20 sm:pb-20 md:px-36">
        <h1 className="font-poppins text-[40px] font-bold text-indigo">
          We help facilities regain control of staffing challenges and related costs
        </h1>
        <h2 className="mt-8 font-poppins text-darkliver">
          Gain access to our tech-enabled solutions and unleash the full potential of your resources without relying on
          cost-prohibitive staffing agencies or similar platforms
        </h2>
        <div className="mx-4 mt-8">
          <Link
            to={paths.contactUs}
            className="mx-4 w-full cursor-pointer rounded-full bg-ultramarinblue py-2 px-6 font-poppins text-xl font-medium text-white md:ml-8 md:w-auto"
          >
            Request Demo
          </Link>
          <button className="mt-4 w-full cursor-pointer rounded-full bg-ultramarinblue py-2 px-6 font-poppins text-xl font-medium text-white md:mx-4 md:mt-0 md:w-auto">
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};
